<div cdkDropList [cdkDropListDisabled]="isReadOnly()" (cdkDropListDropped)="drop(options, $event);">
    <div cdkDrag *ngFor="let optionElement of options; let index = index;" class="option-container">
      <div class="option-container-row">
        <div>
          <a [class.is-disabled]="isReadOnly()" class="button is-handle" style="position:relative;" cdkDragHandle>
            <i class="fas fa-arrows-alt" aria-hidden="true"></i>
            <div style="position:absolute; font-size:0.7em; top:2px; left:3px;" *ngIf="hasButtonLabels">
              {{buttonLabels[index]}}
            </div>
          </a>
          <div *ngIf="allowPositioning">
            <fieldset [disabled]="isReadOnly()">
              <div class="align-center"> <label >X  <num-scrub [el]="optionElement" prop="x" [width]="3" [step]="0.1"  [max]="60"></num-scrub></label> </div>
              <div class="align-center"> <label >Y  <num-scrub [el]="optionElement" prop="y" [width]="3" [step]="0.1"  [max]="60"></num-scrub></label> </div>
            </fieldset>
          </div>
        </div>
        <div class="option-container-content">
          <div *ngIf="optionElement.elementType=='text'">
            <div *ngIf="isAdvTextEl(optionElement)">
              <element-config-text [element]="optionElement"></element-config-text>
            </div>
            <div *ngIf="!isAdvTextEl(optionElement)">
                <element-config-mcq-option-text [mcqOption]="optionElement" (change)="emitChange()"></element-config-mcq-option-text>
                <div *ngIf="isShowAdvancedOptions">
                  <input [(ngModel)]="optionElement.isInvisible" type="checkbox" />&nbsp;Is invisible
                </div>
            </div>
          </div>
          <div *ngIf="optionElement.elementType=='audio'">
            <element-config-audio [element]="optionElement"></element-config-audio>  
          </div>
          <div *ngIf="optionElement.elementType=='image'">
            <capture-image [element]="optionElement"></capture-image>
            <asset-library-link [element]="optionElement"></asset-library-link>     
          </div>
          <div *ngIf="optionElement.elementType=='table'">
            <element-config-table [element]="optionElement"></element-config-table>
          </div>
          <div *ngIf="optionElement.elementType=='math'" style="margin-bottom: 0.7em; max-width: 209px;">
            <capture-math [class.no-pointer-events]="isReadOnly()" [obj]="optionElement" [prop]="'content'"></capture-math>
          </div>
          <div *ngIf="optionElement.elementType=='frame'">
            <element-config-frame [element]="optionElement"></element-config-frame>
          </div>
        </div>

        <div class="option-container-buttons">
          <a 
            class="button is-correct-toggle"
            [class.is-disabled]="isReadOnly()"
            [class.is-success] = "optionElement.isCorrect"
            [class.is-danger]  = "!optionElement.isCorrect"
            (click)="toggleOptionCorrect(optionElement)"
          >
            <i 
              class="fa fa-check" 
              [class.fa-check] = "optionElement.isCorrect"
              [class.fa-times] = "!optionElement.isCorrect"
              aria-hidden="true"
            ></i>
          </a>
          <a [class.is-disabled]="isReadOnly()" class="button is-expanded" (click)="removeElement(options, optionElement)">
            <i class="fas fa-trash" aria-hidden="true"></i>
          </a>

        </div>

      </div>
      <div *ngIf="isEnablePartialweights">
        <label>
          <tra slug="auth_partial_weight"></tra>
          <input type="number" [style.width.em]="4" min="0" [(ngModel)]="optionElement.partialWeight"/>
        </label>
      </div>
      <div  style="margin-top: 1em; margin-bottom:1em;" *ngIf="isShowAdvancedOptions" [class.is-disabled]="isReadOnly()">
        <label>
          <tra slug="auth_target_id"></tra>
          <input type="number" [style.width.em]="4" min="0" [(ngModel)]="optionElement.frameInteractionId"/>
        </label>
      </div>
      <div style="display: flex; flex-direction: row; width: 100%; margin-top: 0.5em" *ngIf="isShowAdvancedOptions">
        <label style="width:100%">
            Event: 
            <div [class.is-disabled]="isReadOnly()" class="select is-fullwidth" [style.margin-left.em]="0.5">
                <select [(ngModel)]="optionElement.frameInteractionType">
                    <option value="">None</option>
                    <option *ngFor="let type of eventTypes" [value]="type.id">
                        {{type.caption}}
                    </option>
                </select>
            </div>
        </label>
      </div>
      <div style="margin-top: 0.5em" *ngIf="isShowAdvancedOptions" [class.is-disabled]="isReadOnly()">
        <ng-container *ngIf="optionElement.frameInteractionType === PubSubTypes.ROTATE">
            <label>
                Angle
                <input type="number" [style.width.em]="4" max="359" min="-359" [(ngModel)]="optionElement.frameData.angle"/>
            </label><br>
        </ng-container>
        <ng-container *ngIf="optionElement.frameInteractionType === PubSubTypes.INC_HEIGHT">
            <label>
                Change In Height
                <input type="number" [style.width.em]="4" min="0" [(ngModel)]="optionElement.frameData.heightChange"/>
            </label><br>
            <label>
              Maximum Height
              <input type="number" [style.width.em]="4" min="0" [(ngModel)]="optionElement.frameData.maxHeight"/>
          </label><br>
        </ng-container>
    </div>
      
      <div  *ngIf="isShowAdvancedOptions">
        <element-config-mcq-option-info 
          [mcqOption]="optionElement"
          [showReplaceOptionType]="true"
          [useDistractorAsResponseSlug]="useDistractorAsResponseSlug"
          (replaceOptionType)="replaceOptionType(optionElement, $event)"
        ></element-config-mcq-option-info>
        <div *ngIf="isCanLink">
          <element-config-text-link [element]="optionElement.link"></element-config-text-link>
        </div>
      </div>
    </div>
  </div>

  <select-element 
    [elementTypes]="subElementTypes"
    [isElTypeDefRes]="true"
    (insert)="insertListEntry($event)"
    style="margin-top:1em;" 
  ></select-element>
