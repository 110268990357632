<div class="page-body" [class.is-eqao-bg]="isEqaoStyle()">
    <div>
      <header
      [breadcrumbPath]="breadcrumb"
      [isLoginPage]="isEqaoStyle()"
      [accountType]="accountType"
      ></header>
      <div class="page-content is-fullpage">
        <div class="form-content" *ngIf="!isFormValidated">
          <h2> <tra slug="create_acct_ta_h"></tra> </h2>
          <div class="form-instruction-major">
            <tra slug="g9_create_acc_inst"></tra>
          </div>

          <div class="field">
            <label class="label"> <tra slug="create_acct_inv_code"></tra> </label>
            <div class="control">
              <input class="input" [formControl]="formGroup.controls.invitationCode">
            </div>
          </div>

          <hr/>
          <button (click)="connectExistingAccount()" class="btn-connect-existing-account"><tra-md slug = 'ca_connect_existing_account'></tra-md></button>
            <div *ngIf = "showConnectAccount">
              <label class="label"><tra slug = 'ca_existing_eamil'></tra></label>
              <input class="input" #existingAccount>
              <div style="display: flex; justify-content: flex-end">
                <button (click)="connectAccountCancel()" class="btn-connect-existing-account-cancel"><tra-md slug = 'btn_cancel'></tra-md></button>
                <button (click)="connectAccountOk(existingAccount.value)" class="btn-connect-existing-account-ok"><tra-md slug = 'btn_ok'></tra-md></button>
              </div>
            </div>
          <hr/>
          <div class="field">
            <label class="label"><tra slug="create_acct_first_name_lbl"></tra> </label>
            
            <div class="control">
              <input class="input" [formControl]="formGroup.controls.givenName">
            </div>
          </div>

          <div class="field">
              <label class="label"><tra slug="create_acct_last_name_lbl"></tra> </label>
            <div class="control">
              <input class="input" [formControl]="formGroup.controls.surname">
            </div>
          </div>

          <div class="field" *ngIf="false">
            <label class="label">
              <tra slug="create_acct_phone_lbl"></tra> <span class="optional-tag"><tra slug="create_acct_optional_lbl"></tra></span>
            </label>
            <div class="control">
              <input class="input" [formControl]="formGroup.controls.phoneNumber">
            </div>
          </div>

          <div class="field">
            <label class="label">
              <tra slug="create_acct_email_lbl"></tra>
            </label>
            <div class="control">
              <input class="input" [formControl]="formGroup.controls.emailAddress">
            </div>
            <div class="form-instruction-minor">
              <tra slug="create_acct_email_instr"></tra>
            </div>
          </div>

          <div class="field">
            <label class="label">
              <tra slug="create_acct_password_cr_lbl"></tra>
            </label>
            <div class="control">
              <input class="input" type="password" [formControl]="formGroup.controls.password">
            </div>
            <div>
              <button class="button is-small is-fullwidth" (click)="togglePasswordPolicy()">
                <tra style="color: #6d4716;" *ngIf="!isPasswordPolicyShowing" slug="btn_view_pass_policy"></tra>
                <tra style="color: #6d4716;" *ngIf="isPasswordPolicyShowing" slug="btn_hide_pass_policy"></tra>
              </button>
              <div *ngIf="isPasswordPolicyShowing" class="read-detail">
                <markdown 
                  class="markdown" 
                  [data]="lang.tra('txt_password_advice_and_policy')" 
                  ngPreserveWhitespaces
                ></markdown>
              </div>
            </div>
          </div>


          <div class="field">
            <label class="label">
              <tra slug="create_acct_password_cnf_lbl"></tra>
            </label>
            <div class="control">
              <input class="input" type="password" [formControl]="formGroup.controls.passwordConfirm">
            </div>
          </div>
<!-- 
          <div> 
            <label>
              <input type="checkbox">
              I agree to the Terms and Conditions of this website. 
            </label>
          </div> -->

          <div *ngIf="isFormFailed" class="notification is-warning">
              <!-- formFailReason: {{formFailReason}} -->
            <tra-md  *ngIf="!isRefreshingFailReason" [slug]="formFailReason"></tra-md>
          </div>

          <div>
            <button (click)="submitForm()" [disabled]="isSubmitting" class="button is-success is-large is-fullwidth">
              <tra slug="create_acct_submit_btn"></tra>
            </button>
          </div>

        </div>
        <div class="form-content" *ngIf="isFormValidated">
          <h2> <tra slug="create_acct_created_main_txt"></tra> </h2>
          <div class="form-instruction-major" *ngIf="!emailConfirmed">
            <tra-md slug="create_acct_created_main_instr"></tra-md>
          </div>
          <div class="form-instruction-major" *ngIf="emailConfirmed">
            <tra-md slug="create_acct_created_same_email" [props]="getLoginRoute()"></tra-md>
          </div>
          <div class="form-popout-info">
            <div><tra slug="create_acct_created_email_txt1"></tra></div>
            <div>
              <a>{{emailUsed}}</a>
            </div>
          </div>
          <!-- <div>
            <a 
              routerLink="/{{lang.getCurrentLanguage()}}/test-admin/dashboard"
              class="button is-main is-fullwidth "
            ><tra slug="btn_continue"></tra></a>
          </div> -->
        </div>
      </div>
    </div>
    <footer [hasLinks]="true" *ngIf="!isEqaoStyle()"></footer>
  </div>
  