import { annotationEditInfo } from "../element-render-annotation/model";
import { audioEditInfo } from "../element-render-audio/model";
import { bookmarkLinkEditInfo } from "../element-render-bookmark-link/model";
import { cameraEditInfo } from "../element-render-camera/model";
import { canvasBookmarkEditInfo, canvasEditInfo } from "../element-render-canvas/model";
import { customInteractionEditInfo } from "../element-render-custom-interaction/model";
import { customMcqEditInfo, customMcqOptionEditInfo } from "../element-render-custom-mcq/model";
import { dndEditInfo } from "../element-render-dnd/model";
import { docLinkEditInfo } from "../element-render-doc-link/model";
import { frameEditInfo } from "../element-render-frame/model";
import { graphicsEditInfo } from "../element-render-graphics/model";
import { graphingEditInfo } from "../element-render-graphing/model";
import { groupingEditInfo } from "../element-render-grouping/model";
import { hotspotEditInfo } from "../element-render-hotspot/model";
import { hotTextEditInfo } from "../element-render-hottext/model";
import { imageEditInfo } from "../element-render-image/model";
import { inputEditInfo } from "../element-render-input/model";
import { insertionEditInfo } from "../element-render-insertion/model";
import { matchingEditInfo } from "../element-render-matching/model";
import { mathEditInfo } from "../element-render-math/model";
import { mcqEditInfo, mcqOptionEditInfo, optionEditInfo } from "../element-render-mcq/model";
import { micEditInfo } from "../element-render-mic/model";
import { moveableDndEditInfo } from "../element-render-moveable-dnd/model";
import { orderEditInfo, orderOptionEditInfo } from "../element-render-order/model";
import { readerEditInfo } from "../element-render-reader/model";
import { resultsPrintEditInfo } from "../element-render-results-print/model";
import { selectableTextEditInfo, selectTextEditInfo } from "../element-render-select-text/model";
import { selectTableEditInfo } from "../element-render-selection-table/model";
import { solutionEditInfo } from "../element-render-solution/model";
import { tableEditInfo, tableTextEditInfo } from "../element-render-table/model";
import { textLinkEditInfo } from "../element-render-text-link/model";
import { textEditInfo, textParagraphEditInfo } from "../element-render-text/model";
import { uploadEditInfo } from "../element-render-upload/model";
import { validatorEditInfo } from "../element-render-validator/model";
import { videoEditInfo } from "../element-render-video/model";
import { ElementType, IElementTypeDef } from "./index";


export const ElementTypeDefs: { [key: string]: IElementTypeDef; } = {
  VIRTUAL_TOOLS: { id: ElementType.VIRTUAL_TOOLS, caption: "auth_virtual_tools", icon: "fa-ruler", isDisabled: false, editingInfo: customInteractionEditInfo },
  CUSTOM_INTERACTION: { id: ElementType.CUSTOM_INTERACTION, caption: "auth_custom_interaction", icon: "fa-american-sign-language-interpreting", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: customInteractionEditInfo },
  VALIDATOR: { id: ElementType.VALIDATOR, caption: "auth_validator\n", icon: "fa-check", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: validatorEditInfo },
  RESULTS_PRINT: { id: ElementType.RESULTS_PRINT, caption: "auth_results_print", icon: "fa-print", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: resultsPrintEditInfo },
  SOLUTION: { id: ElementType.SOLUTION, caption: "Solution", icon: "fa-columns", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: solutionEditInfo },
  ANNOTATION: { id: ElementType.ANNOTATION, caption: "Annotation", icon: "fa-link", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: annotationEditInfo },
  SELECT_TABLE: { id: ElementType.SELECT_TABLE, caption: "auth_selection_table", icon: "fa-table", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: selectTableEditInfo },
  CUSTOM_MCQ: { id: ElementType.CUSTOM_MCQ, caption: "auth_custom_mcq", icon: "fa-bars", isKeyboardAccessible: false, isPrintFriendly: false, editingInfo: customMcqEditInfo },
  MOVEABLE_DND: { id: ElementType.MOVEABLE_DND, caption: "auth_drag_drop", icon: "fa-hand-pointer", isPrintFriendly: false, editingInfo: moveableDndEditInfo },
  INSERTION: { id: ElementType.INSERTION, caption: "Insertion", isDisabled: false, icon: "fas fa-sign-in-alt fa-rotate-90", isKeyboardAccessible: true, isPrintFriendly: false, editingInfo: insertionEditInfo },
  TEXT: { id: ElementType.TEXT, caption: "auth_text", icon: "fa-font", isKeyboardAccessible: true, editingInfo: textEditInfo },
  TEXT_LINK: { id: ElementType.TEXT_LINK, caption: "auth_reader_link", icon: "fa-link", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: textLinkEditInfo },
  BOOKMARK_LINK: { id: ElementType.BOOKMARK_LINK, caption: "auth_bookmark_link", icon: "fa-link", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: bookmarkLinkEditInfo },
  FRAME: { id: ElementType.FRAME, caption: "auth_frame", icon: "fa-crop-alt", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: frameEditInfo },
  CANVAS: { id: ElementType.CANVAS, caption: "Canvas", isDisabled: false, icon: "fa-sticky-note", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: canvasEditInfo },
  CANVAS_BOOKMARK: { id: ElementType.CANVAS_BOOKMARK, caption: "Bookmark Target", isDisabled: true, icon: "fa-sticky-note", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: canvasBookmarkEditInfo },
  SELECT_TEXT: { id: ElementType.SELECT_TEXT, caption: "auth_text_selection", icon: "fa-font", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: selectTextEditInfo },
  TABLE: { id: ElementType.TABLE, caption: "auth_table", icon: "fa-table", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: tableEditInfo },
  MATH: { id: ElementType.MATH, caption: "auth_math", icon: "fa-pencil-alt", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: mathEditInfo },
  IMAGE: { id: ElementType.IMAGE, caption: "Image", icon: "fa-image", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: imageEditInfo },
  VIDEO: { id: ElementType.VIDEO, caption: "auth_video", icon: "fa-file-video", isKeyboardAccessible: true, isPrintFriendly: false, editingInfo: videoEditInfo },
  AUDIO: { id: ElementType.AUDIO, caption: "Audio", isDisabled: false, icon: "fa-file-audio", isKeyboardAccessible: false, isPrintFriendly: false, editingInfo: audioEditInfo },
  DOC_LINK: { id: ElementType.DOC_LINK, caption: "Document", isDisabled: false, icon: "fa-file-pdf", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: docLinkEditInfo },
  INPUT: { id: ElementType.INPUT, caption: "auth_keyboard_input", isInteractive: true, isDisabled: false, icon: "fa-keyboard", isKeyboardAccessible: true, editingInfo: inputEditInfo },
  MCQ: { id: ElementType.MCQ, caption: "auth_multiple_choice", isInteractive: true, icon: "fa-bars", isKeyboardAccessible: true, editingInfo: mcqEditInfo },
  ORDER: { id: ElementType.ORDER, caption: "auth_ordering", isInteractive: true, icon: "fa-sort", isKeyboardAccessible: true, isPrintFriendly: false, editingInfo: orderEditInfo },
  GRAPHING: { id: ElementType.GRAPHING, caption: "auth_geometry", isInteractive: true, isDisabled: false, icon: "fa-paint-brush", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: graphingEditInfo },
  DND: { id: ElementType.DND, caption: "auth_drag_drop", isInteractive: true, isDisabled: true, icon: "fa-hand-pointer-o", isKeyboardAccessible: true, isPrintFriendly: false, editingInfo: dndEditInfo },
  HOTSPOT: { id: ElementType.HOTSPOT, caption: "Hot Spot", isInteractive: true, isDisabled: true, icon: "fa-asterisk", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: hotspotEditInfo },
  HOTTEXT: { id: ElementType.HOTTEXT, caption: "Hot Text", isInteractive: true, isDisabled: true, icon: "fa-commenting", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: hotTextEditInfo },
  MATCHING: { id: ElementType.MATCHING, caption: "Matching", isInteractive: true, isDisabled: false, icon: "fa-compress", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: matchingEditInfo },
  READER: { id: ElementType.READER, caption: "Reader", isDisabled: true, icon: "fa-bars", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: readerEditInfo },
  MIC: { id: ElementType.MIC, caption: "Microphone", isInteractive: true, isDisabled: false, icon: "fa-microphone", isKeyboardAccessible: true, isPrintFriendly: false, editingInfo: micEditInfo },
  CAMERA: { id: ElementType.CAMERA, caption: "Camera", isInteractive: true, isDisabled: false, icon: "fa-camera", isKeyboardAccessible: true, isPrintFriendly: false, editingInfo: cameraEditInfo },
  UPLOAD: { id: ElementType.UPLOAD, caption: "auth_file_upload", isInteractive: true, isDisabled: false, icon: "fa-upload", isKeyboardAccessible: true, isPrintFriendly: false, editingInfo: uploadEditInfo },
  GRAPHICS: { id: ElementType.GRAPHICS, caption: "auth_graphics", isInteractive: true, isDisabled: true, icon: "fa-image", isKeyboardAccessible: true, isPrintFriendly: true, editingInfo: graphicsEditInfo },
  GROUPING: { id: ElementType.GROUPING, caption: "auth_grouping", isInteractive: true, isDisabled: false, icon: "fa-object-group", isKeyboardAccessible: true, isPrintFriendly: false, editingInfo: groupingEditInfo },
  PAINTING: { id: ElementType.TEXT, caption: "Painting", icon: "fa-font", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: textEditInfo },
  SCULPTING: { id: ElementType.TEXT, caption: "Sculpting", icon: "fa-font", isKeyboardAccessible: false, isPrintFriendly: true, editingInfo: textEditInfo },
  OPTION: { id: ElementType.OPTION, caption: "", icon: "", editingInfo: optionEditInfo },
  MCQ_OPTION: { id: ElementType.MCQ_OPTION, caption: "", icon: "", editingInfo: mcqOptionEditInfo },
  CUSTOM_MCQ_OPTION: { id: ElementType.CUSTOM_MCQ_OPTION, caption: "", icon: "", editingInfo: customMcqOptionEditInfo },
  ORDER_OPTION: { id: ElementType.ORDER_OPTION, caption: "", icon: "", editingInfo: orderOptionEditInfo },
  SELECTABLE_TEXT: { id: ElementType.SELECTABLE_TEXT, caption: "", icon: "", editingInfo: selectableTextEditInfo },
  TABLE_TEXT: {id: ElementType.TABLE_TEXT, caption: "", icon: "", editingInfo: tableTextEditInfo},
  TEXT_PARAGRAPH: {id: ElementType.TEXT_PARAGRAPH, caption: "", icon: "", editingInfo: textParagraphEditInfo}
};

export const elementTypes: IElementTypeDef[] = [
  ElementTypeDefs.TEXT,
  ElementTypeDefs.CANVAS,
  ElementTypeDefs.FRAME,
  ElementTypeDefs.TABLE,
  ElementTypeDefs.MATH,
  ElementTypeDefs.INSERTION,
  ElementTypeDefs.IMAGE,
  ElementTypeDefs.GRAPHICS,
  ElementTypeDefs.MOVEABLE_DND,
  ElementTypeDefs.VIDEO,
  ElementTypeDefs.AUDIO,
  ElementTypeDefs.DOC_LINK,
  ElementTypeDefs.INPUT,
  ElementTypeDefs.MCQ,
  ElementTypeDefs.ORDER,
  ElementTypeDefs.GRAPHING,
  ElementTypeDefs.SELECT_TEXT,
  // ElementTypeDefs.DND,
  // ElementTypeDefs.MATCHING,
  ElementTypeDefs.GROUPING,
  // ElementTypeDefs.HOTSPOT,
  // ElementTypeDefs.HOTTEXT,
  ElementTypeDefs.SELECT_TABLE,
  ElementTypeDefs.CUSTOM_MCQ,
  ElementTypeDefs.MIC,
  ElementTypeDefs.CAMERA,
  ElementTypeDefs.UPLOAD,
  // ElementTypeDefs.READER,
  ElementTypeDefs.SOLUTION,
  ElementTypeDefs.RESULTS_PRINT,
  ElementTypeDefs.VALIDATOR,
  ElementTypeDefs.CUSTOM_INTERACTION,
  ElementTypeDefs.VIRTUAL_TOOLS,
];
