<div> 
  <fieldset [disabled]="isReadOnly()">
    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isOptional"> 
        <tra slug="auth_optional_response"></tra>
      </label>
    </div>
    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        Mark as completed on: &nbsp;
        <input type="number" [style.width.em]="4" min="1" [(ngModel)]="element.reqMinimumPlacedTarget">
         completed target(s)
         <input type="checkbox" [style.width.em]="4" min="1" [(ngModel)]="element.perGroupCheck">
          Per Group
      </label>
      <label class="checkbox">
        
      </label>
    </div>

    <div>
      <label class="checkbox">
        <input type="checkbox" [formControl]="isMatchingMode">
        Allow only one option per group?
      </label>
    </div>

    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isPartialWeightsEnabled">
        <tra slug="auth_enable_partial_weights"></tra>
      </label>
    </div>

    <div>
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isTargetListHorizontal">
        Target elements are horizontally layed out
      </label>
    </div>

  <!--<div>
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="element.enableProportionalScoring">
      Enable proportional scoring?
    </label>
  </div>-->
    <div>
      <label class="checkbox">
        <input type="checkbox" [formControl]="isVerticalLayout">
        User vertical layout
      </label>
    </div>

    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="this.element.isShowSeparator"/>
        Edit Separator
      </label>
    </div>

    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox"><input type="checkbox" [(ngModel)]="this.element.isShowSeparatorInRenderHidden"/>
        Hide Separator</label>
    </div>

    <div>
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isOptionsReusable">
        Are options reusable
      </label>
    </div>
    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" (change)="element.isTargetsSame ? turnOffOrdered() : ''" [(ngModel)]="element.isTargetsSame">
        Are targets same
      </label>
    </div>
    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" (change)="element.isTargetsOrdered ? turnOffSame() : ''" [(ngModel)]="element.isTargetsOrdered">
        Are targets ordered
      </label>
    </div>

    <div>
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isGroupSizeLimited">
        Limit Group Size?
      </label>
    </div>

    <div *ngIf="element.isGroupSizeLimited && this.element.isShowAdvancedOptions">
      <label class="checkbox">
        Group Size
        <input type="number" [(ngModel)]="element.groupSize" [style.width.em]="4">
      </label>
    </div>
    
    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.topAlignTargets">
        Top Align Targets
      </label>
    </div>

    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.removeContainerBgBorder">
        Remove Drag and Drop Conatiner's Border and Background
      </label>
    </div>

    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.removeDraggableBgBorder">
        Remove Draggable's Border and Background
      </label>
    </div>

    <!--<div>
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.enableProportionalScoring">
        Enable proportional scoring?
      </label>
    </div>-->

    <div>
      <label class="checkbox">
        <input type="checkbox" [formControl]="isInstructionsDisabled">
        Disable drag instruction text?
      </label>
    </div>

    <div>
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isTargetsAbove">
        Place targets above?
      </label>
    </div>
    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.sortDraggablesInGroups">
        Place targets in group container by order
      </label>
    </div>
    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isGroupContainerNoWrap">
        Don't Wrap Group Container
      </label>
    </div>
  </fieldset>

  <div *ngIf="this.element.isShowSeparator">
    <hr [style.background-color]="'#000000'" />
    <span>Separator:</span>
    <fieldset [disabled]="isReadOnly()">
      <div class="control is-expanded">
        <div [class.is-disabled]="isReadOnly()" class="select is-fullwidth">
          <select [(ngModel)]="element.separatorType">
            <option *ngFor="let sepType of separatorTypes" [value]="sepType.id">
              {{sepType.caption}}
            </option>
          </select>
        </div>
      </div>
    </fieldset>
    <div *ngIf="element.separatorType == 'text'">
      <element-config-text *ngIf="element.separatorText" [element]="element.separatorText"></element-config-text>
    </div>
    <div *ngIf="element.separatorType == 'image'">
      <element-config-image *ngIf="element.separatorImage" [hasStates]='false' [element]="element.separatorImage"></element-config-image>
    </div>
    <div *ngIf="element.separatorType == 'canvas'">
      <element-config-canvas *ngIf="element.separatorCanvas" [element]="element.separatorCanvas"></element-config-canvas>
    </div>
  </div>

  <fieldset [disabled]="isReadOnly()">
    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="this.element.isNotPadded">
        Target Contents is not padded in target container
      </label>
    </div>
    <div>
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="this.element.wrapInTargets">
        Shrink Target Contents To Fit Container
      </label>
    </div>
    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="this.element.customTargetDim" (click)="this.updateChangeCounter()">
        Is Custom Target Dimension
      </label>
    </div>
    <div *ngIf="this.element.customTargetDim && this.element.isShowAdvancedOptions">
        Target Dimensions
        <input type="number" placeholder="Width" min="5" [(ngModel)]="this.element.targetWidth" [style.width.em]="4">
        <input type="number" placeholder="height" min="5" [(ngModel)]="this.element.targetHeight" [style.width.em]="4">
    </div>
    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isDragContainerWidthSet" (click)="this.updateChangeCounter()">
        Is Draggable Container Width Set
      </label>
    </div>
    <div *ngIf="this.element.isDragContainerWidthSet && this.element.isShowAdvancedOptions">
        Draggable Container Width
        <input type="number" placeholder="Width" min="5" [(ngModel)]="this.element.dragContainerWidth" [style.width.em]="4">
    </div>
    <div *ngIf="this.element.isShowAdvancedOptions">
      Draggable Dimensions
      <input type="number" placeholder="Width"  [(ngModel)]="element.draggableWidth" [style.width.em]="4">
      <input type="number" placeholder="height"  [(ngModel)]="element.draggableHeight" [style.width.em]="4">
    </div>
    <div *ngIf="this.element.isShowAdvancedOptions">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isTargetsContentsCentred" >
        Center Draggable Contents
      </label>
    </div>
    <label class="checkbox" *ngIf="this.element.isShowAdvancedOptions">
      Group Width
      <input type="number" min="30" [(ngModel)]="this.element.groupWidth" [style.width.em]="4">
    </label>
    <hr/>
    Layout
    <select [(ngModel)]="element.arrangementType">
      <option *ngFor="let arrangement of arrTypes; let index = index" [value]="arrangement.id">
        {{arrangement.caption}}
      </option>
    </select>
    <div *ngIf="isManual()">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isManualDraggableOrientationHorizontal">
        Horizontal Draggable Orientation
      </label>
    </div>
    <div *ngIf="isManual()">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isDraggableContainerGone">
        Get Rid Of Draggable Container
      </label>
    </div>
    <div class="element-group-header">
      <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
      <tra slug="auth_draggable_elements"></tra>
    </div>
  </fieldset>
    <div class="field" *ngIf="isShowAdvancedOptions.value">
      <color-panel [element]="this.element.draggableColourScheme"></color-panel>

    </div>
    <div 
      cdkDropList
      [cdkDropListDisabled]="isReadOnly()" 
      (cdkDropListDropped)="drop(element.draggables, $event);"
    >
      <div 
        cdkDrag 
        *ngFor="let draggableEl of element.draggables"
      >
        <div class="draggable-row" >
          <a 
            cdkDragHandle 
            class="button is-handle" 
            [class.is-info]="isSelectedPosRef(draggableEl)"
            [class.is-disabled]="isReadOnly()"
            (mousedown)="selectPosElByRef(draggableEl)"
          >
            <i class="fas fa-arrows-alt" aria-hidden="true"></i>
          </a>
          <fieldset [disabled]="isReadOnly()">
            <div *ngIf="element.isOptionsReusable && draggableEl.targetID2Amount && !element.isTargetsSame">
              <div style="display:flex;flex-wrap:wrap;" *ngFor="let targ of element.targets">
                {{targ.id}}
                <input style="width: 4em;" type="number" [(ngModel)]="draggableEl.targetID2Amount[targ.id]">
              </div>
            </div>

          </fieldset>
          <div class="row-content">
            <capture-dnd-style 
              [element]="draggableEl" 
              (change)="throttledUpdate()"
              (idClick)="selectPosElByRef(draggableEl)"
            ></capture-dnd-style>
            <div>
              Key ID: <input [(ngModel)]="draggableEl.key_id" style="width:5em">
            </div>
            <fieldset [disabled]="isReadOnly()">
              <label>
                Label
                <input type="text" [(ngModel)]="draggableEl.label"/>
              </label>
              <label *ngIf="element.sortDraggablesInGroups">
                Sort Order
                <input type="text" [style.width.em]="4" [(ngModel)]="draggableEl.sortOrder"/>
              </label>
              <div *ngIf="element.arrangementType=='manual'">
                <label>
                  <input type="number" [(ngModel)]="draggableEl.x" [style.width.em]="4" class="input is-small"> X
                </label>
                <label>
                  <input type="number" [(ngModel)]="draggableEl.y" [style.width.em]="4" class="input is-small"> Y
                </label><br>
              </div>
              <div *ngIf="element.isPartialWeightsEnabled" style="margin-top:1em">
                <label>
                  <tra slug="auth_partial_weight"></tra>
                  <input style="margin-left: 1em;" type="number" [style.width.em]="4" min="0" [(ngModel)]="draggableEl.partialWeight"/>
                </label>
              </div>
            </fieldset>
            <element-config [contentElement]="draggableEl.element">
            </element-config>
          </div>
          <a [class.is-disabled]="isReadOnly()" class="button is-expanded" (click)="removeElement(element.draggables, draggableEl)">
            <i class="fas fa-trash-alt" aria-hidden="true"></i>
          </a>
        </div>
        <element-config-mcq-option-info *ngIf="this.element.isShowAdvancedOptions" [mcqOption]="draggableEl" notesSlug="ie_notes"></element-config-mcq-option-info>  
      </div>
    </div>
    
    <div>
      <select-element 
        [elementTypes]="subElementTypes"
        (insert)="insertDraggableElement($event)"
        style="margin-top:1em;" 
      ></select-element>
    </div>    
    <hr/>
    <div class="element-group-header">
      <i class="fa fa-crosshairs" aria-hidden="true"></i>
      Target Elements
    </div>
    <div class="field" *ngIf="isShowAdvancedOptions.value">
      <color-panel [element]="this.element.targetColourScheme"></color-panel>
    </div>
    <div 
      cdkDropList 
      [cdkDropListDisabled]="isReadOnly()" 
      (cdkDropListDropped)="drop(element.targets, $event);"
    >
      <div 
        cdkDrag 
        *ngFor="let draggableEl of element.targets"
      >
        <div class="draggable-row">
          <a
              cdkDragHandle
              class="button is-handle"
              [class.is-disabled]="isReadOnly()"
              [class.is-info]="isSelectedPosRef(draggableEl)"
              (mousedown)="selectPosElByRef(draggableEl)"
          >
            <i class="fas fa-arrows-alt" aria-hidden="true"></i>
          </a>
          <div class="row-content">
            <capture-dnd-style
                    [element]="draggableEl"
                    (change)="throttledUpdate()"
                    (idClick)="selectPosElByRef(draggableEl)"
            ></capture-dnd-style>
            <div>
              Key ID: <input [(ngModel)]="draggableEl.key_id" style="width:5em">
            </div>
            <!-- <div *ngIf="element.arrangementType=='manual'">
              <label>
                <input type="number" [(ngModel)]="draggableEl.x" [style.width.em]="4" class="input is-small"> X
              </label>
              <label>
                <input type="number" [(ngModel)]="draggableEl.y" [style.width.em]="4" class="input is-small"> Y
              </label><br>
            </div> -->
            <fieldset [disabled]="isReadOnly()" *ngIf="this.element.isShowAdvancedOptions">
              <div *ngIf="element.arrangementType=='manual'">
                <label>
                  <input type="number" [(ngModel)]="draggableEl.x" [style.width.em]="4" class="input is-small"> X
                </label>
                <label>
                  <input type="number" [(ngModel)]="draggableEl.y" [style.width.em]="4" class="input is-small"> Y
                </label><br>
              </div>
              <label>
                Header Height
                <input type="number" [style.width.em]="4" [(ngModel)]="draggableEl.headerMinHeight">
              </label>
            </fieldset>
            <element-config  [contentElement]="draggableEl.element"></element-config>
            <div *ngIf="this.element.isShowAdvancedOptions">
              Background Color
              <input type="color" [(ngModel)]="draggableEl.bgColor">
            </div>
            <div *ngIf="this.element.isShowAdvancedOptions">
              Header Text Color
              <input type="color" [(ngModel)]="draggableEl.headerTextColor">
            </div>
            <div *ngIf="this.element.isShowAdvancedOptions">
              Header Background Color
              <input type="color" [(ngModel)]="draggableEl.headerBGColour">
            </div>
            <div *ngIf="this.element.isShowAdvancedOptions">
              Fix Width
              <input [style.width.em]="4" type="number" [(ngModel)]="draggableEl.width">
            </div>
            <div *ngIf="this.element.isShowAdvancedOptions">
              Fix Height
              <input [style.width.em]="4" type="number" [(ngModel)]="draggableEl.height">
            </div>
            <div *ngIf="isShowAdvancedOptions.value">
              <div style="font-size:14px;font-weight:bold;">
                Background Image:
              </div>
              <capture-image *ngIf="draggableEl.backgroundImg" [element]="draggableEl.backgroundImg"></capture-image>
              <asset-library-link *ngIf="draggableEl.backgroundImg" [element]="draggableEl.backgroundImg"></asset-library-link>
            </div>
          </div>
          <a [class.is-disabled]="isReadOnly()" class="button is-expanded" (click)="removeElement(element.targets, draggableEl)">
            <i class="fas fa-trash" aria-hidden="true"></i>
          </a>
        </div>
        <element-config-mcq-option-info *ngIf="this.element.isShowAdvancedOptions" [mcqOption]="draggableEl"></element-config-mcq-option-info>
      </div>

    </div>

    <fieldset [disabled]="isReadOnly()" *ngIf="this.element.isShowAdvancedOptions">
      <div>
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="element.isRenderDelimiter">
          Render Delimiter
        </label>
      </div>
      <div>
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="element.isShowDelimiter">
          Show Delimiter Configuration
        </label>
      </div>
    </fieldset>

    <div *ngIf="this.element.isShowDelimiter">
      <span><tra slug="auth_delimiter"></tra></span>
      <fieldset [disabled]="isReadOnly()">
        <div class="control is-expanded">
          <div [class.is-disabled]="isReadOnly()" class="select is-fullwidth">
            <select [formControl]="delimiterType">
              <option *ngFor="let delimType of delimiterTypes" [value]="delimType.id">
                {{delimType.caption}}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="delimiterType.value == 'text'">
          <input type="text" class="input" [(ngModel)]="element.delimiter">
        </div>
      </fieldset>
      <div *ngIf="delimiterType.value == 'image'">
        <element-config-image [hasStates]='false' [element]="element.delimiterImg"></element-config-image>
      </div>
      <div *ngIf="delimiterType.value == 'icon'">
        <delimiter (setDelim)="setDelimiter($event)"></delimiter>
      </div>
      <hr [style.background-color]="'#000000'" />
    </div>

    <div>
      <select-element 
        [elementTypes]="subTargetElementTypes"
        (insert)="insertTargetElement($event)"
        style="margin-top:1em;" 
      ></select-element>
    </div>
    <hr *ngIf="this.element.isShowAdvancedOptions"/>

    <fieldset [disabled]="isReadOnly()">
      <div *ngIf="element.isTargetsOrdered">
        Specify order of id's separated by commas (eg, 1,3,2)
        <div *ngFor="let combo of element.correctOrders">
          <hr>
          <input type="text" [(ngModel)]='combo.order'><br>
          <button type="submit" (click)="deleteOrder(combo)" class="button is-primary">Delete Order</button>
        </div>
        <button type="submit" (click)="insertOrder()" class="button is-primary">Add Order</button>
      </div>

      <div *ngIf="element.isTargetsSame">
        <div *ngFor="let combo of element.correctCombinations">
          <hr>
          <div style="display:flex;flex-wrap:wrap;" *ngFor="let drag of element.draggables">
            {{drag.id}}
            <input style="width: 4em;" type="number" [(ngModel)]="combo.id2amount[drag.id]">
          </div>
          <button type="submit" (click)="deleteCombination(combo)" class="button is-primary">Delete Combination</button>
        </div>
        <button type="submit" (click)="insertCombinations()" class="button is-primary">Add Combination</button>
      </div>
  
      <div *ngIf="this.element.isShowAdvancedOptions">
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="element.enableProportionalScoring">
          Enable proportional scoring?
        </label>
      </div>
      <div class="form-row" *ngIf="element.enableProportionalScoring && this.element.isShowAdvancedOptions">
        <div class="form-row-label">
            Score Weight
        </div>
        <div class="form-row-input">
          <input type="text" class="input" [formControl]="scoreWeight">
        </div>
      </div>
    </fieldset>

    <div>
      <label class="checkbox">
        <input type="checkbox" [formControl]="isShowAdvancedOptions">
        <tra slug="auth_show_advanced_options"></tra>
      </label>
    </div>
  </div>