<div class="footer dont-print">
  <div *ngIf="hasLinks && getFooter() && getFooter().length" class="footer-links">
    <div class="footer-column" *ngFor="let footerColumn of getFooter()">
      <div
        *ngFor="let footer of footerColumn"
        [ngSwitch]="isFooterInternal(footer)"
      >
        <a *ngSwitchCase="true" routerLink="/{{clang()}}{{footer.route}}"><tra [slug]="footer.caption"></tra></a>
        <a *ngSwitchCase="false" target="_blank" href="{{lang.tra(footer.link)}}"><tra [slug]="footer.caption"></tra></a>
      </div>
    </div>
  </div>
  <div class="footer-lower">
      <div class="copyright">
        <tra [slug]="getCopyright()"></tra>
      </div>
      <!-- <div>
        <button *ngIf="isAppealShown" [routerLink]="renderAppealLink()" class="footer-comment-link">
          <tra slug="btn_file_an_appeal"></tra>
        </button>
        <span *ngIf="isAppealShown" style="color: #999;margin:0em 0.5em;">•</span>
        <button *ngIf="hasComments" (click)="supportReqActivate()" class="footer-comment-link">
          <tra whitelabel="tech_support" slug="title_feedback"></tra>
        </button>
      </div> -->
  </div>
</div>