<div>
    <a (click)="openLink()" (keydown.enter)="openLink()" [style.color]="isHighContrast ? '#ff0' : ''" tabindex="0" style="display: flex; flex-direction: row; justify-content: flex-start" > 
            <i *ngIf="!element.isIconDisabled" class="fas fa-link"></i>&nbsp;
            <!-- <i style="width: 1.2em;" *ngIf="!element.isIconDisabled && textLinkType[textLinkCaption] === 'link'" class="fas fa-link">&nbsp;</i>
            <i style="width: 1.2em;" *ngIf="!element.isIconDisabled && textLinkType[textLinkCaption] === 'document'" class="fas fa-file">&nbsp;</i>
            <i style="width: 1.2em;" *ngIf="!element.isIconDisabled && textLinkType[textLinkCaption] === 'playback'" class="fas fa-play-circle">&nbsp;</i> -->

            <!-- use tra-md for &nbsp; and other html rendering if needed -->
            <tra-md [isCondensed]="true" style="font-weight: normal; text-decoration:underline" [slug]="textLinkCaption"></tra-md>
            <render-image *ngIf="element.thumbNail" [element]="element.thumbNail"></render-image>
    </a>
</div>
