import * as moment from 'moment-timezone';
import { Component, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { AccountType } from '../../constants/account-types';
import { LoginGuardService } from '../../api/login-guard.service';
import { ITestWindow, MyCtrlOrgService, TestControllerGroupId } from '../my-ctrl-org.service';
import { AuthService, getFrontendDomain, DB_TIMEZONE } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { ScrollService } from '../../core/scroll.service';
import { LangService } from '../../core/lang.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreadcrumbsService, IBreadcrumbRoute } from '../../core/breadcrumbs.service';
import { ITestWindowSummary, IAccountsSummary } from './types/db';
import { FormControl } from '@angular/forms';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { userGroupTypes, userGroupSummaryColumns } from './types/tc-summary';
import { CODEBOOK_G9, tcDataDlApiPrefix } from './types/codebook-g9';
import { transformThousandsSeparator } from '../../core/styleprofile.service'
import { mtz } from '../../core/util/moment';
import { APIAdminColumnImportList } from './import/config';
import { PageModalController, PageModalService } from 'src/app/ui-partial/page-modal.service';
import { TestControllerModal } from 'src/app/ui-schooladmin/data/types';
import { UserRoles } from 'src/app/api/models/roles';

// interface ITestWindow {
//   id: number,
//   captionStr: string,
//   dateStart: string
//   dateEnd: string,
//   numSessionsCreated: number,
//   numSessionsAdministered: number,
//   numResultsPendingRelease: number,
// }

interface ILangReqRow {
  testSessionId: number,
  date: string,
  time: string,
  location:string,
  locationInst: string,
  locationDetails: string,
  lang: string,
  tally: number,
  isOrganized:boolean,
  notes: string,
  __notesInput: FormControl,
  __isShowLocDetail?: boolean,
}

export interface ILangReqRecord {
  id: number, // test session id
  test_window_id: number,
  lang_req: string,
  tally: number,
  institution_name: string,
  room: string,
  campus_building: string,
  address: string,
  city: string,
  province: string,
  postal_code: string,
  phone_number: string,
  date_time_start: string,
}
 enum UserGroupType {
  SCHOOL_BOARDS = 'districts',
  SCHOOLS = 'schools',
  TEACHERS = 'teachers',
  SCHOOL_CLASSES = 'classes',
  STUDENTS = 'students',
}

export enum SummaryCol {
  Registered = 'Registered',
  BoardCompletedTech = 'BoardCompletedTech',
  CompletedTech = 'CompletedTech',
  CompletedSample = 'CompletedSample',
  CompletedSampleAndTech = 'CompletedSampleAndTech',
  StartedOper = 'StartedOper',
  CompletedOper = 'CompletedOper',
  StuReportsAccessed = 'StuReportsAccessed',
}

export enum TCView {
  EQAO_G9 = 'EQAO_G9',
  EQAO_G10 = 'EQAO_G10',
  EQAO_MPT = 'EQAO_MPT',
}



@Component({
  selector: 'view-tc-dashboard',
  templateUrl: './view-tc-dashboard.component.html',
  styleUrls: ['./view-tc-dashboard.component.scss']
})
export class ViewTcDashboardComponent implements OnInit, OnDestroy {

  // services
  constructor(
    private loginGuard: LoginGuardService, //
    private router:Router,
    private route:ActivatedRoute,
    private breadcrumbsService:BreadcrumbsService,
    private scrollService:ScrollService,
    private lang:LangService,
    private auth:AuthService,
    private routes:RoutesService,
    public myCtrlOrg:MyCtrlOrgService,
    private whitelabel:WhitelabelService,
    private pageModalService: PageModalService,
  ) { }

  // rendered vars

  public districts = 'districts';
  public isDisableHidden:boolean = true;
  public breadcrumb:IBreadcrumbRoute[];
  public isInited:boolean;
  public isLoaded:boolean = true;
  public isLoadFailed:boolean;
  public summaryTableTimestamp: string;
  public testWindowSummaries:ITestWindow[];
  public testWindowOldSessions = {};
  public accountSummaries:IAccountsSummary;
  public showLast30days = new FormControl(false);
  public showTestSessionInProgressData = new FormControl(false);
  public TCView = TCView;
  // public selectedView:TCView =TCView.EQAO_G9; // to change if ordering by route
  public IS_DOWNLOADS_DISABLED = false;
  public IS_TW_SELECT_DISABLED = false;
  public IS_COUNTS_DISABLED = false;
  public IS_COUNTS_WARNING = false; 
  // public views:IMenuTabConfig<TCView>[] = [
  //   {
  //     id:TCView.EQAO_G9,
  //     caption: this.lang.tra('Grade 9 Mathematics'), //'Technical Readiness',
  //   },
  //   {
  //     id:TCView.EQAO_G10,
  //     caption: this.lang.tra('OSSLT/TPCL'), //'Technical Readiness',
  //   },
  //   {
  //     id:TCView.EQAO_MPT,
  //     caption: this.lang.tra('Math Proficiency Test'), //'Technical Readiness',
  //   }
  // ];

  pageModal: PageModalController;
  TestControllerModal = TestControllerModal;
  inputDailyReportPwd: string;
  dailyReportPwd: string;
  isWrongPasswordPrompted: boolean = false;

  activeScoringWindows: any[] = [];
  scoringWindowTestWindows: any[] = [];
  selectedScoringWindowToAdd;
  selectedScoringWindowForItems;

  public userGroupTypes = userGroupTypes;
  public UserGroupType = UserGroupType;
  public userGroupSummaryColumns = userGroupSummaryColumns;
  public codebookTable = CODEBOOK_G9;
  public UserRoles = UserRoles;

  // logical vars
  private routeSub:Subscription;
  private myCtrlOrgSub:Subscription;

  isActivateDailyReporting:boolean;
  isShowQa:boolean;
  isFullWidth:boolean;
  loadedDataTracker: TCView;

  // API utility
  currentSummaryColIndex = 0;
  testWindows:ITestWindow[] = [ 
  ];
  summaryTable:{[col:string]: {[row:string]: number}} = {};
  ttriUrl = null;
  twFiles;

  allWinSummaryRecords;
  isLoadingAllWinSummary:boolean;
  allWinGridOptions:any = {
    columnDefs: [
      { headerName:'Administration Window', field:'window_title', width:200 },
      { headerName:'Registered', field:'num_registered', width:200 },
      { headerName:'Fully Participating (Complete)', field:'num_complete', width:200 },
      { headerName:'Remaining to Complete', field:'num_to_complete',width:200 },
      { headerName:'RtC %', field:'perc_to_complete',width:120 },
      { headerName:'Partially Participating (Started)', field:'num_start', width:200 },
      { headerName:'Remaining to Start', field:'num_to_start',width:200 },
      { headerName:'RtS %', field:'perc_to_start',width:120 },
    ],
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
    },
  };

  syncScoreProfilesPreview;
  
  responseSetsTransferLogs: any[] = [];

  scoringWindowForm = {
    name: '',
    start_on: '',
    end_on: '',
    is_active: 1,
    is_paused: 0,
    is_scoring_disabled: 1,
    is_locked: 0,
    is_hidden_for_scorers: 1,
    lang: ''
  };

  yesOrNoSelections = [{
    value: 1,
    description: 'Yes'
  },{
    value: 0,
    description: 'No'
  }]

  langSelections = [{
    value: 'en',
    description: 'en'
  },{
    value: 'fr',
    description: 'fr'
  }]

  now: Date;

  responseSetsFrom: any[] = [];
  responseSetsTo: any[] = [];

  displayCheckmark = (params) => {
    if (params.value) return '<span><i class="fa fa-check"></i></span>'
    else return ''
  }
  
  linearMisallocGridOptions:any = {
    columnDefs: [
      { headerName:'UID', field:'uid', width:100 },
      { headerName:'Student OEN', field:'StudentOEN', width:150 },
      { headerName:'Slug', field:'slug', width:150 },
      { headerName:'ID', field:'id', width:100 },
      { headerName:'Student is linear', field:'student_is_Linear', width:180, cellRenderer: this.displayCheckmark },
      { headerName:'Test attempt is linear', field:'test_attempt_is_linear', width:200, cellRenderer: this.displayCheckmark },
      { headerName:'Test attempt started on', field:'test_attempt_started_on', width:200 }, 
      { headerName:'Test attempt is closed', field:'test_attempt_is_closed', width:200, cellRenderer: this.displayCheckmark },
      { headerName:'Section Index', field:'section_index', width:150 },
    ],
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
    },
  };

  revokeExistingAdmin = false;
  dryRun = true;
  bulkLoadRecords = [];

  async loadAllWinSummary(){
    const summaryLoaders = [];
    const summaryRecords = [];
    this.isLoadingAllWinSummary = true;
    this.testWindows.forEach(window => {
      if (window.is_qa){ return }
      const record = {
        tw_id: window.id,
        window_title: this.renderTwTitle(window.title),
        num_registered: 0,
        num_complete: 0,
        num_to_complete: 0,
        perc_to_complete: '',
        num_start: 0,
        num_to_start: 0,
        perc_to_start: '',
        scoring_ids : window.marking_window_id,
      }
      summaryRecords.push(record);
      const loadRecordField = async (colId: SummaryCol, field:string) => {
        const data = await this.myCtrlOrg.getSummaries(colId, window)
        const val = data.summaryData ? data.summaryData.students : 0;
        record[field] = val;
      }
      [
        loadRecordField(SummaryCol.Registered, 'num_registered'),
        loadRecordField(SummaryCol.CompletedOper, 'num_complete'),
        loadRecordField(SummaryCol.StartedOper, 'num_start'),
      ].forEach( a => summaryLoaders.push(a) )
    })
    await Promise.all(summaryLoaders);
    const formatPerc = num => Math.floor((isNaN(num) ? 0 : num)*100)+'%'
    summaryRecords.forEach(record => {
      record.num_to_complete = record.num_registered - record.num_complete;
      record.num_to_start = record.num_registered - record.num_start;
      record.perc_to_complete = formatPerc(record.num_to_complete/record.num_registered);
      record.perc_to_start = formatPerc(record.num_to_start/record.num_registered);
    })
    this.allWinSummaryRecords = summaryRecords;
    this.isLoadingAllWinSummary = false;
  }
  downloadAllWinSummary(){
    this.allWinGridOptions.api.exportDataAsCsv();
  }
  
  // init / destroy
  ngOnInit() {
    this.scrollService.scrollToTop();
    this.loginGuard.activate([AccountType.TEST_CTRL]);
    this.routeSub = this.route.params.subscribe(routeParams => {
      // this.setupId = routeParams['setupId'];
      this.breadcrumb = [
        this.breadcrumbsService.TESTCTRL_DASHBOARD(),
        // this.breadcrumbsService._CURRENT(this.lang.tra(currentPageNameTrans), this.router.url),
      ]
      this.initRouteView();
      this.pageModal = this.pageModalService.defineNewPageModal();
    });
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }

    if (this.myCtrlOrgSub) {
      this.myCtrlOrgSub.unsubscribe();
    }
  }

  initRouteView(){
    this.myCtrlOrgSub = this.myCtrlOrg.sub().subscribe( async orgInfo => {
      if (!this.isInited && orgInfo){
        this.isInited = true;
        this.testWindows = await this.myCtrlOrg.loadWindows();
        this.bulkLoadRecords = await this.myCtrlOrg.loadSchoolAdministratorsBulkLoadRecords();
        const sys_daily_reporting_config = await this.auth.apiFind('public/cron/reporting', {
          query: {}
        });
        this.isActivateDailyReporting = sys_daily_reporting_config?.[0].isEnabled;
        this.dailyReportPwd = sys_daily_reporting_config?.[0].password;
        this.now = new Date();
      }
    })
  }

  resetScoringWindowForm() {
    return {
      name: '',
      start_on: '',
      end_on: '',
      is_active: 1,
      is_paused: 0,
      is_scoring_disabled: 1,
      is_locked: 0,
      is_hidden_for_scorers: 1,
      lang: ''  
    };
  }
  validateScoringWindowForm() {
    if (this.scoringWindowForm.name == '') { return false }
    if (this.scoringWindowForm.start_on == '') { return false }
    if (this.scoringWindowForm.end_on == '') { return false }
    if (this.scoringWindowForm.lang == '') { return false }
    
    return true;    
  }
  openScoringWindowModal(mode: string, id?: number) {
    let config = {
      mode
    }
    const type = TestControllerModal.SCORING_WINDOW_FORM;
    switch (mode) {
      case 'add':
        this.scoringWindowForm = this.resetScoringWindowForm();
        break;
      case 'edit':
        config['id'] = id;
        for (let window of this.activeScoringWindows) {
          if (window.id == id) {
            this.scoringWindowForm = window;
          }
        }
        break;
    }
    this.pageModal.newModal({
      type,
      config,
      finish: () => {}
    })    
  }

  async openScoringWindowItemsModal() {
    if (!this.selectedScoringWindowForItems) {
      return;
    }
    const record = await this.auth.apiFind(this.routes.TEST_CTRL_TEST_WINDOW_SCORING_WINDOW_ITEMS, {
      query: {
        marking_window_id: this.selectedScoringWindowForItems.id
      }
    });

    const {
      newMarkingWindowItems,
      batchPolicyList
    } = record[0];

    const config = {
      newMarkingWindowItems: newMarkingWindowItems,
      batchPolicyList: batchPolicyList
    }
    const type = TestControllerModal.SCORING_WINDOW_ITEMS
    this.pageModal.newModal({
      type,
      config,
      finish: () => {}
    })    
  }

  async saveScoringWindow(mode: string, id?: number) {
    if (mode == 'add') {
      if (this.validateScoringWindowForm()) {
        await this.auth.apiCreate(this.routes.TEST_CTRL_TEST_WINDOW_SCORING_WINDOW_SETUP, {...this.scoringWindowForm, test_window_id: this.getTestWindowId()});
      } else {
        alert('Please make sure to enter all fields.')
        return;
      }
    }
    if (mode == 'edit') {
      if (this.validateScoringWindowForm()) {
        await this.auth.apiPatch(this.routes.TEST_CTRL_TEST_WINDOW_SCORING_WINDOW_SETUP, id, this.scoringWindowForm);
      } else {
        alert('Please make sure to enter all fields.')
        return;
      }
    }
    this.pageModal.closeModal()
    await this.loadScoringWindowTestWindows();
  }

  async saveScoringWindowItems() {
    const newMarkingWindowItems = this.cModal().config.newMarkingWindowItems
    
    for (let item of newMarkingWindowItems) {
      if (!item.batch_alloc_policy_id || !item.caption) {
        alert('Please make sure to enter all fields.');
        return;
      }
    }
    await this.auth.apiCreate(this.routes.TEST_CTRL_TEST_WINDOW_SCORING_WINDOW_ITEMS, {
      newMarkingWindowItems
    });

    this.pageModal.closeModal();
    alert('The new scoring window items are successfully created.');
  }

  async promptScoreProfileSync(marking_window_id: number) {
    this.syncScoreProfilesPreview = await this.auth.apiGet(this.routes.TEST_CTRL_SYNC_SCORING_AUTHORING, 0, {
      query: {
        marking_window_id
      }
    });
    this.syncScoreProfilesPreview.updatedFrom.map(e => {
      e.score_profile_id_from = e.score_profile_id
      delete e.score_profile_id;
    });
    this.syncScoreProfilesPreview.updatedTo.map(e => {
      e.score_profile_id_to = e.score_profile_id
      delete e.score_profile_id;
    });

    // Merge two arrays (updatedFrom, updatedTo)
    this.syncScoreProfilesPreview['merged'] = this.syncScoreProfilesPreview.updatedFrom.map(from => {
      const obj = this.syncScoreProfilesPreview.updatedTo.find(to => 
        to.marking_window_id === from.marking_window_id &&
        to.item_id === from.item_id && 
        to.skill_code === from.skill_code);
      return { ...from, ...obj };
    });

    const config = {
      marking_window_id
    };
    this.pageModal.newModal({
      type: TestControllerModal.SYNC_SCORE_PROFILES,
      config,
      finish: () => {}
    })
  }

  
  isDailyReportingProcessed = true;
  async runDailyReporting() {
    if (this.isDailyReportingProcessed) {
      this.isDailyReportingProcessed = false;
      await this.auth.apiCreate('public/cron/reporting', {});
      alert('Daily reporting has been successfully forwarded to the receivers');  
      this.isDailyReportingProcessed = true;
    }
  }

  promptReportingPassword() {
    this.isWrongPasswordPrompted = false;
    const config = {};
    this.pageModal.newModal({
      type: TestControllerModal.DAILY_REPORTING_PWD,
      config,
      finish: () => {}
    })
  }

  showDailyReporting() {
    const config = {};
    this.pageModal.newModal({
      type: TestControllerModal.DAILY_REPORTING,
      config,
      finish: () => {}
    })
  }

  cModal() { return this.pageModal.getCurrentModal(); }
  cmc() { return this.cModal().config; }

  checkPwd() {
    if (this.inputDailyReportPwd === this.dailyReportPwd) {
      this.showDailyReporting();
    } else {
      this.isWrongPasswordPrompted = true;
    }
  }

  getRoleContext(){
    return {
      tc_group_id: this.myCtrlOrg.selectedWindow?.test_ctrl_group_id,
      test_window_id: this.myCtrlOrg.selectedWindow.id
    }
  }


  getBaseRoute(){
    return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}`;
  }
  getViewRoute(viewSlug:TCView){
    return this.getBaseRoute()+'/'+viewSlug
  }
  
  isWindowSelected(){
    return !!this.myCtrlOrg.selectedWindow
  }

  selectTestWindowFocus(window:ITestWindow) {
    this.myCtrlOrg.selectedWindow = window;
    // this.auth
    //   .apiGet( this.routes.TEST_CTRL_DATA_FILE, this.myCtrlOrg.selectedWindow.ttriFileId, {} )
    //   .then( (file: any) => {
    //     this.ttriUrl = file.secureUrl;
    //   })
    //   .catch(e => {
    //     alert('Cannot make this file available for you.');
    //   });
    this.loadSummaryData();
    this.loadLinearMisallocRecords();
    this.loadScoringWindowTestWindows();
    this.loadResponseSetsTransferLogs();
  }

  currentLang(){
    return this.lang.c();
  }

  async syncScoreProfileId(marking_window_id: number) {
    this.pageModal.closeModal();
    await this.auth.apiCreate(this.routes.TEST_CTRL_SYNC_SCORING_AUTHORING, {
      marking_window_id
    }).then(res => {
      alert('The score profiles have been successfully synced from the authoring system to the scoring system');  
    }).catch(err => {
      alert('ERROR: Please provide a marking_window_id');
    });
  }

  renderTimestampAsUIString() {
    const timestamp = this.summaryTableTimestamp;
    const dateFmt = this.lang.tra('datefmt_dashboard_long');
    const timestampFormatted = moment.tz(timestamp, moment.tz.guess()).format(dateFmt);
    return `Summaries Generated At: ${timestampFormatted}`;
  }
  checkActiveWindow(window){
    return (this.myCtrlOrg.selectedWindow === window);
  }
  
  async downloadTransferTable(dataFrame:any, sectionId: number) {
    const tableId = `${sectionId}.${dataFrame.id}`
    const tableRes = await this.auth.apiFind(`${tcDataDlApiPrefix}transfer-table-dl`, {
      query: {
        tc_group_id: 5403,
        test_window_id: this.myCtrlOrg.selectedWindow.id,
        table_id: tableId
      }
    });

    window.open(tableRes[0].secureUrl);
  }
  downloadDataFrame(dataFrame:{apiPath:string}){
    if (this.myCtrlOrg.selectedWindow){
      return this.auth.dataFilePath(tcDataDlApiPrefix+dataFrame.apiPath, {
        test_window_id: this.myCtrlOrg.selectedWindow.id, 
        tc_group_id: this.myCtrlOrg.selectedWindow.test_ctrl_group_id
      });
    }
  }
  renderTwTitle(twTitle:any){
    if (twTitle){
      try {
        const title = JSON.parse(twTitle);
        return title[this.lang.c()] || title['en']
      }
      catch(e){
        return '---'
      }
    }
  }
  renderDate(dateStr){
    return mtz(dateStr).format('MMM D, YYYY')
  }
  // downloadG10DataFrame(dataFrame:{apiPath:string}){
  //   const projectId = this.getCurrentG10ProjectId();
  //   if (projectId == 138 && dataFrame.apiPath == 'registrations/students') {
  //     return this.ttriUrl;
  //   }
  //   return this.auth.dataFilePath(tcDataDlApiPrefix+dataFrame.apiPath, { projectId });
  // }
  getRawLink(userGroupType:any){
    return this.auth.dataFilePath(
      tcDataDlApiPrefix+userGroupType.apiPath, 
      {
        test_window_id: this.myCtrlOrg.selectedWindow.id, 
        tc_group_id: this.myCtrlOrg.selectedWindow.test_ctrl_group_id
      }
    );
  }
  getColLink(userGroupType:string){
    let path = `school-${userGroupType}`;
    if(userGroupType === UserGroupType.SCHOOL_BOARDS) {
      path = 'school-boards';
    } else if (userGroupType === UserGroupType.SCHOOLS) {
      path = 'school';
    }
   return `/en/test-ctrl/${path}`;
  }
  getColValue(userGroupType:string, col:string){
    const colData = this.summaryTable[col];
    if (colData){
      if (colData[userGroupType] !== undefined){
        return transformThousandsSeparator(''+colData[userGroupType], 'en');
      }
    }
    return '--'
  }

  
  async loadTestWindowFiles(){
    // console.log('loadTestWindowFiles')
    this.twFiles = [];
    this.twFiles = await this.myCtrlOrg.getTwFiles()
  }


  islinearMisallocRecordsLoaded:boolean;
  islinearMisallocRecordsLoading:boolean;
  islinearMisallocRecordsLoadFailed:boolean;
  linearMisallocRecords:any[] = [];
  loadLinearMisallocRecords(){
    this.islinearMisallocRecordsLoaded = false
    this.islinearMisallocRecordsLoadFailed = false
    this.islinearMisallocRecordsLoading = true
    const windowIdOfRecords = this.myCtrlOrg.selectedWindow.id

    this.auth.apiFind(this.routes.TEST_CTRL_LINEAR_MISALLOC, {
      query: {
        group_id: TestControllerGroupId,
        test_window_id: this.myCtrlOrg.selectedWindow.id,
      }
    }).then(data => {
      // Only proceed if the currently selected test window is still the one for which the API was called
      if (windowIdOfRecords === this.myCtrlOrg.selectedWindow.id) {
        this.islinearMisallocRecordsLoading = false
        this.islinearMisallocRecordsLoaded = true
        this.linearMisallocRecords = []

        data.forEach(rawRecord => {
          const record = {
            ...rawRecord, 
            student_is_Linear: rawRecord.student_is_Linear === '1',
            test_attempt_is_linear: rawRecord.test_attempt_is_linear === 1,
            test_attempt_is_closed: rawRecord.test_attempt_is_closed === 1,
          }
          this.linearMisallocRecords.push(record)
        })
      }
    })
    .catch(e => {
      if (windowIdOfRecords === this.myCtrlOrg.selectedWindow.id) {
        this.islinearMisallocRecordsLoading = false
        this.islinearMisallocRecordsLoadFailed = true
      }
    });
  }
  downloadLinearMisalloc(){
    this.linearMisallocGridOptions.api.exportDataAsCsv();
  }

  loadSummaryData(){
    this.currentSummaryColIndex = 0;
    this.summaryTable = {};
    if (!this.isShowSummaries()){
      return;
    }
    this.loadTestWindowFiles()
    this.loadRemainingSummary();
  }
  // loadG10SummaryData(){
  //   this.currentG10SummaryColIndex = 0;
  //   this.g10SummaryTable = {};
  //   this.loadRemainingG10Summary();
  // }

  getTestWindowId(){
    return this.myCtrlOrg.selectedWindow?.id
  }

  getTestWindow(){
    return this.myCtrlOrg.selectedWindow
  }

  loadRemainingSummary(){
    this.isLoaded = false;
    const col = this.userGroupSummaryColumns[this.currentSummaryColIndex];
    if (!this.myCtrlOrg.selectedWindow){ console.warn('No window selected'); }
    this.myCtrlOrg
      .getSummaries(col.id)
      .then(data => {
        this.isLoaded = true; // after the first one
        this.summaryTable[col.id] = data.summaryData;
        this.currentSummaryColIndex ++;
        this.summaryTableTimestamp = data.timestamp;
        if (this.currentSummaryColIndex < this.userGroupSummaryColumns.length){
          this.loadRemainingSummary();
        }
        else{
          // console.log('summaryTable', this.summaryTable)
        }
      })
  }

  // loadMPTSummaryData(){
  //   return this.auth
  //     .apiGet( this.routes.TEST_CTRL_SUMMARY, 0, {} )
  //     .then( (res:ITCDashboardSummaryRes) => {
  //       this.isLoaded = true;
  //       this.accountSummaries = res.accounts;
  //       this.testWindowSummaries = res.test_windows.map(this.parseTestWindowSummary);

  //       const testWindowIds = res.test_windows.map(window => window.test_window_id);
  //       this.loadSUnclosedSessions(testWindowIds);
  //     })
  //     .catch(e => {
  //       this.isLoadFailed = true;
  //     })
  // }

  loadSUnclosedSessions(testWindowIds) {
    this.auth.apiFind('public/test-ctrl/sessions-date', {
      query: {
        test_window_ids: testWindowIds
      }
    }).then(res => {
      res.data.forEach(sess => {
        const windowId = sess.id;
        const isClosed = sess.is_closed;
        const startDate = sess.date_time_start;

        const start = moment(startDate);
        const now = moment().subtract(6, 'hour');

        if (start.isBefore(now)) {

          if (windowId in this.testWindowOldSessions) {
            if (!isClosed) {
              this.testWindowOldSessions[windowId] += 1;
            }
          } else {
            if (!isClosed) {
              this.testWindowOldSessions[windowId] = 1;
            }
          }

        }
      });

    });
  }

  getCtrlGroup(){
    const ctrlGroups = new Map();
    let bestMatch:{id:number, n:number} | null = null;
    for (let tw of this.testWindows){
      const id = tw.test_ctrl_group_id;
      const n = (ctrlGroups.get(id)|| 0) + 1
      ctrlGroups.set(id, n);
      if (bestMatch){
        if (n > bestMatch.n){
          bestMatch = {id, n}
        }
      }
      else {
        bestMatch = {id, n}
      }
    }
    const ctrlGroupUniqIds:number[] = [];
    ctrlGroups.forEach((n, id) => {
      ctrlGroupUniqIds.push(+id);
    });
    console.log('ctrlGroups', ctrlGroups)
    const bestMatchId = bestMatch?.id;
    const test_ctrl_group_id = prompt(`The test window will be assigned to a control group. Previous windows have been assigned to the following control groups: ${ctrlGroupUniqIds}. The most frequently used control group is ${bestMatch ? bestMatchId : '(NONE)'}.`, ''+bestMatchId) 

    if (!test_ctrl_group_id){
      throw new Error('NO_test_ctrl_group_id');
    }

    return +test_ctrl_group_id;
  }

  async toggleDailyReporting() {
    await this.auth.apiPatch('public/cron/reporting', null, {
      isActivateDailyReporting: this.isActivateDailyReporting
    });
  }

  createTestWindow(){

    const test_ctrl_group_id = this.getCtrlGroup()

    if (confirm('Proceed in creating a new test window?')){
      return this.auth
        .apiCreate( this.routes.TEST_CTRL_TEST_WINDOW_SUMMARY, {test_ctrl_group_id})
        .then( (res:{id:number, test_ctrl_group_id:number} ) => {
          this.router.navigate([ this.getTestWindowRoute(res) ])
        })
        .catch(e => {
          alert('Cannot create new test window.')
        })
    }
    }

  isLangReqLoaded:boolean;
  isLangReqLoading:boolean;
  isLangReqLoadFailed:boolean;
  langReqRows:ILangReqRow[] = [];
  loadLangReqData(){
    this.langReqRows = []
    this.isLangReqLoading = true;
    return this.auth
      .apiFind( this.routes.TEST_CTRL_LANG_REQ, {} )
      .then( (res:any) => { // :Paginated
        this.isLangReqLoaded = true;
        this.langReqRows = res.data.map(this.parseLangReqRecord);
        this.isLangReqLoading = false;
        // console.log('loadLangReqData', this.langReqRows)
      })
      .catch(e => {
        this.isLangReqLoadFailed = true;
      })
  }


  isInstSummaryLoaded:boolean;
  isInstSummaryLoading:boolean;
  isInstSummaryLoadFailed:boolean;
  instSummaryList:any[] = [];
  loadInstSummary(){
    this.instSummaryList = []
    this.isInstSummaryLoading = true;
    return this.auth
      .apiFind( this.routes.TEST_CTRL_ACCOUNTS_INSTITUTIONS, {} )
      .then( (res:any) => { // :Paginated
        this.isInstSummaryLoaded = true;
        this.instSummaryList = res.data.sort((a,b)=>{
          if (a.name > b. name){ return 1; }
          if (a.name < b. name){ return -1; }
          return 0;
        });
        this.isInstSummaryLoading = false;
      })
      .catch(e => {
        this.isInstSummaryLoadFailed = true;
      })
  }

  parseLangReqRecord = (langReqRecord:ILangReqRecord) : ILangReqRow => {
    const DATE_FMT = 'MMM D, YYYY'
    const TIME_FMT = this.lang.tra('datefmt_time')
    const m = moment(langReqRecord.date_time_start);
    return {
      testSessionId: langReqRecord.id,
      date: m.format(DATE_FMT),
      time: m.format(TIME_FMT),
      location:langReqRecord.city,
      locationInst: langReqRecord.institution_name,
      locationDetails: [
        `${langReqRecord.room}, ${langReqRecord.campus_building}`,
        `${langReqRecord.address}`,
        `${langReqRecord.city} ${langReqRecord.province} ${langReqRecord.postal_code}`,
        `${langReqRecord.phone_number}`,
      ].join('\n'),
      lang: this.processLangReqCode(langReqRecord.lang_req),
      tally: langReqRecord.tally,
      isOrganized: false,
      notes: '',
      __notesInput: new FormControl(),
    }
  }

  private processLangReqCode(langCode:string){
    if (langCode === 'fr'){
      return 'French';
    }
    return 'Other';
  }

  parseTestWindowSummary = (tws:ITestWindowSummary) : ITestWindow => {
    const dateFmt = this.lang.tra('datefmt_dow_time_day_month_year');
    const dateStart = moment.tz(tws.test_window_date_start, moment.tz.guess()).format(dateFmt);
    const dateEnd = moment.tz(tws.test_window_date_end, moment.tz.guess()).format(dateFmt);
    return {
      id: tws.test_window_id,
      test_ctrl_group_id: tws.test_ctrl_group_id, // might be missing
      captionStr: tws.test_window_name ? tws.test_window_name[this.lang.c()] : '',
      dateStart,
      dateEnd,
      numSessionsCreated: tws.num_sessions_created,
      numSessionsAdministered: tws.num_sessions_administered,
      numResultsPendingRelease: tws.num_results_pending_release,
    }
  }

  // DOM utility
  disabled(){
    if (!this.isDisableHidden){
      this.loginGuard.confirmationReqActivate({
        caption: this.lang.tra('txt_feature_disabled'),
        confirm: ()=>{}
      })
    }
  }

  getTestWindowRoute = (window:ITestWindow) => {
    return `/${this.lang.c()}/${AccountType.TEST_CTRL}/test-window/ctrl-group/${window.test_ctrl_group_id}/window/${window.id}` ;
  }

  refreshData(){

  }

  getAccountsRoute(){
    return `/${this.lang.c()}/${AccountType.TEST_CTRL}/accounts/${AccountType.TEST_ADMIN}`;
  }

  isTestCtrlReportingExplorerEnabled(){
    return this.whitelabel.getSiteFlag('TEST_CTRL_REPORT_EXPLR');
  }
  
  
  isShowSummaries(){
    return this.whitelabel.getSiteFlag('IS_TC_SUMM');
  }
  isShowDataDownloads(){
    return this.whitelabel.getSiteFlag('IS_TC_DATA') && !this.IS_DOWNLOADS_DISABLED;
  }

    // testWindowSummaries

  // renderTwTitle(tw:ITestWindow){
  //   return tw.captionStr;
  // }
  renderTwDateStart(tw:ITestWindow){
    return tw.dateStart;
  }
  renderTwDateEnd(tw:ITestWindow){
    return tw.dateEnd;
  }
  renderTwNumSessionsCr(tw:ITestWindow){
    return tw.numSessionsCreated;
  }
  renderOldUnclosed(tw:ITestWindow) {
    return this.testWindowOldSessions[tw.id] || 0;
  }
  renderTwNumSessionsAdm(tw:ITestWindow){
    return tw.numSessionsAdministered;
  }
  renderTwNumResPend(tw:ITestWindow){
    return tw.numResultsPendingRelease;
  }
  // end of: testWindowSummaries
  // Account Summaries
  renderAccountTally(set:string, subset: 'r' | 'l30' | 'p'){
    let val;
    if (this.accountSummaries){
      const tallySummary = this.accountSummaries[set];
      if (tallySummary){
        val = tallySummary[subset];
      }
    }
    return val || 0;
  }


  // end of: Account Summaries
  renderDateFromStr(dateString:string){
    return moment.tz(dateString, moment.tz.guess()).format();
  }
  isOnFirstPage(){
    return true;
  }
  isOnLastPage(){
    return true;
  }
  getCurrentPageNum(){
    return 1
  }
  getMaxPages(){
    return 1
  }

  bulkLoadTemplate(){
    var a = document.createElement("a");
    a.href = this.lang.tra("tc-school-admin-bulk-load-template");
    a.click();
  }

  async bulkLoad(fileList: FileList){
    const adminData = await this.loadAdminData(fileList)
    if(this.validateAdminData(adminData)){
      console.log("Data is good")
      console.log("revokeExistingAdmin " + this.revokeExistingAdmin)
      const data =
      {
        adminData,
        revokeExistingAdmin: this.revokeExistingAdmin,
        dryRun: this.dryRun,
        emailLinkDomain: getFrontendDomain(),
        serverDomain: this.whitelabel.getApiAddress()
      }
      this.auth.apiCreate(this.routes.TEST_CTRL_ACCOUNTS_SCHOOL_ADMINS, data, this.configureQueryParams())
        .then(res => {
          this.bulkLoadRecords = res.concat(this.bulkLoadRecords)
        })
        .catch(err =>{
        })
    }
  }

  configureQueryParams(){
    return {
      query: {
        group_id: TestControllerGroupId,
      }
    }  
  }

  async loadAdminData (fileList: FileList){
    const newData = [];
    const captionToProp = {}
    APIAdminColumnImportList.forEach(column => {
      captionToProp[column] = column
    })

    for(let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);

      const fileTypeFull = file.type;
      const fileType = fileTypeFull.split('/')[0];
      const fileExt = file.name.split('.').pop();

      if (fileExt !== 'xlsx') {  
        return this.loginGuard.quickPopup(this.lang.tra('sa_unsupported_file',undefined,{file_type:fileExt}));
      }

      const res:any = await this.auth.excelToJson(file);
      const jsonRows = res.json;

      
      for(let j=0; j<jsonRows.length; j++) {
        const entry = jsonRows[j];
        //Validate the JSON

        //Remove '__EMPTY' entries, as they indicate null header fields. Not sure why they appear sometimes and not other times.
        for(const key in entry) {
          if(key.startsWith('__EMPTY')) {
            delete entry[key];
          }
        }
        
        let entryPropData = {};
        const entryKeys = Object.keys(entry);

        //Ensure that there are no missing entries in the JSON
        const missingRequiredFields = [];
        for(let caption of Object.keys(captionToProp)) {
          if(entry[caption] === undefined) { //null is okay - means it was empty
            missingRequiredFields.push(caption);
          }
        }
        if (missingRequiredFields.length){
          return this.loginGuard.quickPopup(
            this.lang.tra('sa_invalid_xlsx_struc') + '\n\n' +
            this.lang.tra('sa_invalid_xlsx_struc_fm') + '\n\n' +
            missingRequiredFields.join('  \n') // two spaces before line return ar meaningful because this is being rendered with markdown
          );
        }
        
        //Ensure that all entries in the JSON are valid
        const inclUnusedFields = [];
        for(let caption of entryKeys) {
          if(!captionToProp[caption]) {
            inclUnusedFields.push(caption)
          }
          entryPropData[captionToProp[caption]] = entry[caption];
        }
        if (inclUnusedFields.length){
          return this.loginGuard.quickPopup(
            this.lang.tra('sa_invalid_xlsx_struc') + '\n\n' +
            this.lang.tra('sa_invalid_xlsx_struc_ni') + '\n\n' +
            inclUnusedFields.join('  \n') // two spaces before line return ar meaningful because this is being rendered with markdown
          );
        }

        newData.push(entryPropData);
      }
      return newData
    }
  }

  validateAdminData(adminData){
    //validate the data
    let result = true;
    const checkFields = [
      {
        column: "BoardMident",
        required: false,
        maxLength: 0,
        regExp: /^\d+$/,
      },
      {
        column: "SchoolMident",
        required: true,
        maxLength: 0,
        regExp: /^\d+$/,
      },
      {
        column: "SchoolName",
        required: false,
        maxLength: 255,
      },
      {
        column: "PrincipalEmail",
        required: true,
        maxLength: 200,
        regExp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
      {
        column: "PrincipalFirstName",
        required: false,
        maxLength: 35, // match the school admin invite max length
      },
      {
        column: "PrincipalLastName",
        required: false,
        maxLength: 35, // match the school admin invite max length
      }
    ]

    adminData.forEach( admin =>{
      checkFields.forEach (field => {
        const columnValue = admin[field.column]?admin[field.column].trim():admin[field.column]
        //check require
        if(field.required && (!columnValue || columnValue.length === 0)){
          setTimeout(() => this.loginGuard.quickPopup("Require column data is missing, please check data in column "+field.column), 0);
          result = false;
          return
        }

        //check length
        if(columnValue && field.maxLength > 0 && columnValue.length > field.maxLength){
          setTimeout(() => this.loginGuard.quickPopup("" + columnValue + " is too long for column "+field.column), 0);
          result = false;
          return
        }

        //check RegExp
        if(columnValue && field.regExp && !field.regExp.test(columnValue)){
          setTimeout(() => this.loginGuard.quickPopup("" + columnValue + " is invalid for column "+field.column), 0);
          result = false;
          return
        }
      })
      if(!result){ // an error already found
        return
      }
    })
    return result
  }

  getDryRunStatus(): string {
    const status = this.dryRun ? this.lang.tra('lbl_yes') : this.lang.tra('lbl_no');
    return status;
  }

  getRevokeExistingAdminStatus(): string {
    const status = this.revokeExistingAdmin ? this.lang.tra('lbl_yes') : this.lang.tra('lbl_no');
    return status;
  }

  async loadScoringWindowTestWindows() {
    await this.auth.apiFind(this.routes.TEST_CTRL_TEST_WINDOW_SCORING_WINDOWS, {
      query: {
        test_window_id: this.getTestWindowId()
      }
    })
    .then(res => {
      const { activeScoringWindows, scoringWindowTestWindows } = res[0];
      this.activeScoringWindows = activeScoringWindows;
      this.scoringWindowTestWindows = scoringWindowTestWindows;
    })

  }

  getTestWindowName(scoringWindowTestWindow) {
    if (scoringWindowTestWindow.tw_name) {
      return JSON.parse(scoringWindowTestWindow.tw_name)[`${this.currentLang()}`];
    }
    if (scoringWindowTestWindow.title) {
      return JSON.parse(scoringWindowTestWindow.title)[`${this.currentLang()}`];
    }
  }

  async revokeScoringWindowTestWindow(scoringWindowTestWindow) {
    await this.auth.apiRemove(this.routes.TEST_CTRL_TEST_WINDOW_SCORING_WINDOWS, scoringWindowTestWindow.mwtw_id, {})
    await this.loadScoringWindowTestWindows();
  }

  async addScoringWindowTestWindow() {
    await this.auth.apiCreate(this.routes.TEST_CTRL_TEST_WINDOW_SCORING_WINDOWS, {
      marking_window_id: this.selectedScoringWindowToAdd.id,
      test_window_id: this.getTestWindowId()
    });
    await this.loadScoringWindowTestWindows();
  }

  async loadResponseSetsTransferLogs() {
    this.responseSetsTransferLogs = await this.auth.apiFind(this.routes.TEST_CTRL_TEST_WINDOW_RESPONSE_SETS, {
      query: {}
    });
  }

  async moveResponseSets(from, to) {
    for (let log of this.responseSetsTransferLogs) {
      if (log.marking_window_id_from == from.id && log.marking_window_id_to == to.id) {
        alert('You have already transferred the response sets for this combination.')
        return;
      }
    }
    const processSummary = await this.auth.apiCreate(this.routes.TEST_CTRL_TEST_WINDOW_RESPONSE_SETS, {
      marking_window_id_from: from.id,
      marking_window_id_to: to.id
    });

    const {
      num_response_selections,
      num_taqr_cache,
      num_response_sets,
      num_response_set_selections
    } = processSummary;

    alert(`
      The following number of records are successfully created.
      - Response Selections: ${num_response_selections}
      - TAQR Cache: ${num_taqr_cache}
      - Response Sets: ${num_response_sets}
      - Response Set Selections: ${num_response_set_selections}
    `);
    this.loadResponseSetsTransferLogs();
    this.pageModal.closeModal();
  }

}
