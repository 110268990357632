import * as _ from 'lodash';
import { IPaginatorCtrl } from "../paginator.component";
import { IFilterSetting, FilterSettingMode, FilterDataType, IFilterSettingConfigValue, IFilterSettingConfigList } from "../../capture-filter-range/capture-filter-range.component"
import { Subject } from 'rxjs';
import { Filter } from 'pixi.js-legacy';
export const STANDARD_PAGE_SIZE = 10;
export enum SortMode { // compatible with lodash sort
  NONE = '',
  ASC = 'asc',
  DESC = 'desc',
}
export const strIncludes = (needle:string, haystack:string) => {
  needle = (''+(needle || '')).toLowerCase();
  if (haystack){
    haystack = (''+haystack).toLowerCase()
    return _.includes(haystack, needle);  
  }
}

export enum FilterType {
  INTERSECTION = "intersection",
  UNION = "union"
}
interface IConfig<T> {
  data: T[],
  pageSize?:number,
  configurablePageSize?: boolean;
  // allowNewSortSettings:boolean,
  sortSettings?: {
    [key:string]: SortSetting,
  },
  filterSettings?: {
    [key:string]: FilterCheckFunction
  },
}
type FilterCheckFunction = (entry:any, filter:string) => boolean;
type SortFunction = (entry:any) => string | number | boolean;
type SortSetting = string | SortFunction; // either sort by element prop or a function
interface ISortSetting {
  id: string,
  sortSetting: SortSetting,
  mode: SortMode,
}

export class MemDataPaginated<T>  {

  private data: T[];
  private paginator: IPaginatorCtrl;
  private currentPageData = [];
  private sortSettings:ISortSetting[] = [];
  public activeFilters:{[key:string]: IFilterSetting} = {};
  public unionFilters:{[key:string]: IFilterSetting} = {}
  // filterUpdate:Subject<boolean> = new Subject();
  // public get filterChanges() : string {
  //   return this.filterUpdate;
  // }
  

  constructor(private config:IConfig<T>){
    this.paginator = {
      pageSize: config.pageSize || STANDARD_PAGE_SIZE,
      configurablePageSize: config.configurablePageSize,
      loadNewData: (numSkip:number) => this.refreshPageSelection(numSkip)
    }
    this.injestNewData(config.data);
  }

  public removeData(removeCondition: Function) {
    this.injestNewData(this.data.filter( entry =>  !removeCondition(entry) ));
  }

  public injestNewData(data:any[]){
    this.data = data.concat([]);
    this.refreshSort();
  }

  refresh(){
    this.refreshPageSelection((this.paginator.currentPage-1) * this.paginator.pageSize)
  }

  public refreshFilters(type?:string){ 
    if (type) this.refreshSort(type);
    else this.refreshSort();
  }
  private refreshSort(type?:string){
    let data = this.data;
    data = this.applyAllFilters(data);
    if (type && type=="assets") {
      data = this.applyAssetSorting(data)
    } else {
      data = this.applyAllSorts(data);
    }
    this.config.data = data;
    this.refresh();
  }

  getFilteredData(){
    return this.config.data;
  }

  numEntries(){
    return this.config.data.length;
  }

  getEntries(){
    return this.config.data
  }

  private applyAllFilters(data:T[]){
    if (!this.config.filterSettings){
      this.config.filterSettings = {};
    }
    Object.keys(this.activeFilters).forEach(id => {
      const filter:IFilterSetting = this.activeFilters[id];
      if (filter){
        const filterSetting = this.config.filterSettings[id];
        let filterConfig;
        let filterValue:string;
        if (filter.mode === FilterSettingMode.VALUE){
          filterConfig = <IFilterSettingConfigValue> filter.config
          filterValue = filterConfig.value;
        }
        else if(filter.mode === FilterSettingMode.LIST){
          filterConfig = <IFilterSettingConfigList> filter.config
          filterValue = filterConfig.selectedValue;
          let list: any[] = filterConfig.list;
          if(filterValue == list[0].val){
            return;
          }
        }
        if (filterValue){
          if (typeof filterSetting === 'function'){
            data = _.filter(data, entry => filterSetting(entry, filterValue) );
          }
          else{
            data = _.filter(data, entry => {
              const entryVal:string | number = entry[id];
              if (typeof entryVal === 'number'){
                return (entryVal === +filterValue);
              }
              else{
                return strIncludes(filterValue, entryVal)
              }
            });
          }
        }
      }
    });

    const dataFound = new Map()
    let hasUnionFilters = false
    Object.keys(this.unionFilters).forEach(id=>{
      const filter: IFilterSetting = this.unionFilters[id]
      if (filter) {
        const filterSetting = this.config.filterSettings[id]
        if (filter.mode === FilterSettingMode.VALUE) {
          const filterConfig = <IFilterSettingConfigValue> filter.config
          let filterValue:string = filterConfig.value
          let found = []
          if (filterValue && filterValue!='') {
            hasUnionFilters = true
            if (typeof filterSetting === 'function'){
              found = _.filter(data, entry => filterSetting(entry, filterValue) );
            }
            else{
              found = _.filter(data, entry => {
                const entryVal:string | number = entry[id];
                if (typeof entryVal === 'number'){
                  return (entryVal === +filterValue);
                }
                else{
                  return strIncludes(filterValue, entryVal)
                }
              });
            }
          }
          found.forEach((item)=>{
            if (dataFound.get(item)==undefined) {
              dataFound.set(item, true)
            }
          })
        }
      }
    })
    if (hasUnionFilters) {
      const newData = []
      dataFound.forEach((value, key)=>{
        newData.push(key)
      })
      data = newData
    }
    return data;
  }

  private applyAllSorts(data:T[]){
    this.sortSettings.forEach(sortSetting =>{
      if (typeof sortSetting.sortSetting === 'string'){
        // to do when a use case appears
        const prop = <string> sortSetting.sortSetting;
        data = _.orderBy(data, entry => (''+entry[prop]).toLowerCase(), sortSetting.mode);
      }
      else if (typeof sortSetting.sortSetting === 'function'){
        // to do when a use case appears
        const propExtractor:Function = sortSetting.sortSetting;
        data = _.orderBy(data, entry => propExtractor(entry), sortSetting.mode);
      }
      else {
        
      }
    });
    return data;
  }

  private applyAssetSorting(data:T[]) {
    const numItemsComp = function (asset) {
      if (asset['item_ids']) {
        return asset['item_ids'].length
      }
      return 0;
    }
    const yearSort = function(asset) {
      if (asset['submitted_on']) {
        const d = new Date(asset['submitted_on'])
        return d.getFullYear()
      }
      return ""
    }

    const monthSort = function(asset) {
      if (asset['submitted_on']) {
        const d = new Date(asset['submitted_on'])
        return d.getMonth()
      }
      return ""
    }

    const daySort = function(asset) {
      if (asset['submitted_on']) {
        const d = new Date(asset['submitted_on'])
        return d.getDate()
      }
      return ""
    }
    data = _.orderBy(data, [yearSort, monthSort, daySort, numItemsComp], ['desc', 'desc', 'desc', 'desc'])
    return data;
  }

  private currentNumSkip:number;
  private refreshPageSelection(numSkip:number){
    if(!numSkip || numSkip < 0){
      numSkip = 0
    }
    this.currentNumSkip = numSkip;

    return new Promise((resolve, reject) => {
      this.currentPageData = this.config.data.slice( numSkip, numSkip + this.paginator.pageSize);
      // console.log('numSkip', numSkip, this.config.data.length, this.paginator.pageSize, this.currentPageData)
      this.paginator.totalRecords = this.config.data.length;
      this.paginator.currentRecords = this.currentPageData.length;
      resolve();
    })
  }


  private getSortSetting(id:string){
    return this.sortSettings.filter(setting => setting.id === id)[0];
  }
  
  public getSortSettingMode(id:string):SortMode{
    const sortSetting = this.getSortSetting(id);
    if (sortSetting){
      return sortSetting.mode;
    }
    return SortMode.NONE;
  }
  
  toggleSortSetting(id:string){
    let sortSetting = this.getSortSetting(id);
    if (!sortSetting){
      sortSetting = this.addSortSetting(id);
    }
    const currentSetting = this.getSortSettingMode(id);
    switch(currentSetting){
      case SortMode.DESC: this.removeSortSetting(id); break;
      case SortMode.ASC:  sortSetting.mode = SortMode.DESC; break;
      default:
      case SortMode.NONE: sortSetting.mode = SortMode.ASC;  break;
    }
    this.refreshSort();
  }

  private addSortSetting(id:string, mode?: SortMode, sortSetting?:SortSetting){
    this.removeSortSetting(id);
    mode = mode || SortMode.NONE;
    sortSetting = sortSetting || this.config.sortSettings[id];
    if ( !(sortSetting || typeof sortSetting === 'function') ) {
      sortSetting = id;
    }
    const sortSettingEntry = { id, mode, sortSetting };
    this.sortSettings.push(sortSettingEntry);
    return sortSettingEntry;
  }

  private removeSortSetting(id:string){
    this.sortSettings = this.sortSettings.filter(setting => setting.id !== id)
  }

  public getPaginatorCtrl(){
    return this.paginator;
  }

  gotoEntryPage(entry:any){
    const i = this.config.data.indexOf(entry);
    const pageIndex = Math.floor((i+1) / this.paginator.pageSize);
    this.paginator.currentPage = pageIndex+1;
    this.refresh();
  }

  public getPage(){
    return this.paginator.currentPage;
  }

  getRelativeOrdinal(index:number){
    return  ((this.paginator.currentPage-1)*this.paginator.pageSize) + index + 1
  }

  public getCurrentPageData(){
    return this.currentPageData;
  }
}
