import { API_ADDRESS_LOCAL, API_ADDRESS_LOCALB } from "../../api/api-addr/l";
import { IContextData } from "./common";

export const VEA:IContextData = {
  id: 'VEA',
  homepageRoute: '/en/general/login/false',
  brandName: 'Vretta eAssessment',
  logo: '',
  footer: null,
  apiAddress: (hostname:string) => {
    switch(hostname){
      case 'localhost': 
        // return 'https://eassessment-qc-api.vretta.com'
        return API_ADDRESS_LOCALB;
      case 'eassessment-qc.vretta.com':
        case 'dt6dnz30h5udd.cloudfront.net':
        return 'https://eassessment-qc-api.vretta.com'
      case 'eassessment-uat.vretta.com':
        return 'https://eassessment-uat-api.vretta.com'
      default: 
        return 'https://api-eassessment.vretta.com';
    }
  },
  siteFlags: {
    'IS_VEA': true,
    'IS_NBED': true, // until distinct whitelabel is created
    'INST_MNG_TT': true,
    'TEST_CTRL_REPORT_EXPLR': true,
    'TEST_CTRL_DATA_QUERY': true,
    'IS_TC_SUMM': true,
    'IS_SMCS': true,  // for St.Michael's College School
    'HIDDEN_RESTRICTION': false,
    'IS_CREATE_ACC_BUTTON_ENABLED': false
  },
  siteText: {
    copyright: ' © Vretta Inc. 2020',
    supportEmail: 'support@vretta.com',
    supportPhone: '',
    my_inst: 'my_school',
    my_inst_admin: 'you_are_admin_school',
    my_inst_manage_acct: 'mng_manage_teachers',
    currentLogo: 'https://www.vretta.com/logo.svg',
    test_session_dur: '24'
  },
  hosts: [
      'eassessment.vretta.com',
      'localhost:4200'
  ],
  defaultTimezone: 'America/Vancouver',
}
