import { IContentElementTextLink } from "../element-render-text-link/model";
import { ElementType, IContentElement, IEntryStateScored, IScoredResponse } from "../models";

export interface IContentElementMcq extends IContentElement, IScoredResponse {
    currentSettings?: string;
    defaultDropdownText?: string;
    displayStyle: McqDisplay;
    fontSizeDropdownOptions?: boolean
    gridCellWidth?: number;
    gridNumColumns?: number;
    hasFrame?: boolean;
    HCSelectionBorderColor?: string;
    height?: number;
    isBgClear?: boolean;
    isBgClearAlways?: boolean;
    isBorderColor?: boolean;
    isCanLink?: boolean;
    isColoredLikert?: boolean;
    isContentsCentered?: boolean;
    isContentsCenteredVertically?: boolean;
    isContentsVertPaddDisabled?: boolean;
    isCustomHoverEffect?: boolean
    isDynamicallyCorrect?: boolean;
    isEnablePartialweights?: boolean;
    isHCBorderColor?: boolean
    isHideOptionActivated?: boolean
    isLimittedWidth?: boolean;
    isMultiSelect?: boolean;
    isNoThickBorder?: number;
    isOffsetSelectionBorder?: boolean;
    isOnRightSideOptionIndicator?: boolean;
    isOptionLabelsDisabled?: boolean;
    isOptionsBgColor?: boolean;
    isOptionsBoxShadow?: boolean;
    isOptionsFontColor?: boolean;
    isPolaroidStyle?: boolean;
    isRadioBackgroundHidden?: boolean;
    isRadioBackgroundHiddenAlways?: boolean;
    isRadioDisabled?: boolean;
    isScrambled?: boolean;
    isSelectionBorder?: Boolean;
    isSelectionScale?: Boolean;
    isSelectToggle?: boolean;
    isVerticallyCenterOptionIndicator?: boolean;
    marginBottom?: number;
    marginRight?: number;
    maxOptions?: number;
    mcqAreaHeight?: number;
    minHeight?: number;
    optionDropshadowBlur?: number;
    optionDropshadowColor?: string;
    optionDropshadowX?: number;
    optionDropshadowY?: number;
    optionHeight?: number;
    optionHeightSet?: boolean;
    options: Array<IContentElementMcqOption>;
    optionsBgColor?: string;
    optionsFontColor?: string;
    selectionBorder?: string;
    selectionBorderColor?: string;
    selectionScale?: number;
    width?: number;
    isTextLeftAligned?: boolean;
    maxCustomDDWidth?: number;
    framePopIds?: string;
    dropdownWidth?: number;
    maxCustomDDHeight?: number;
    avoidInversionOnHighContrast?: boolean;
    lockInputBoxOnSelection?: boolean;
    inputBoxIDs?: string;
    hideDropdownArrowIcon?: boolean;
  }


  export const mcqEditInfo = {
    editExcludeFields: ['options']
  }

  export interface IContentElementOption extends IContentElement {
    voiceover?: any;
    optionId: number;
    optionType: ElementType;
    content: any;
  }

  export const optionEditInfo = {
    editExcludeFields: [],
    editTextFields: ['content']
  }

  export interface IContentElementMcqOption extends IContentElementOption {
    content: string;
    elementType: string;
    frameData?: any;
    frameInteractionId?: number;
    frameInteractionType?: string;
    height?: number;
    isCorrect?: boolean;
    link?: IContentElementTextLink;
    optionId: number;
    partialWeight?: number;
    url?: string;
    width?: number;
    x?: number;
    y?: number;
  }
  
  export const mcqOptionEditInfo = {
    editExcludeFields: [],
    superType: ElementType.OPTION
  }

  export enum McqDisplay {
    BUBBLE = "bubble",
    DROPDOWN = "dropdown",
    CUSTOM_DROPDOWN = "custom_dropdown",
    FREEFORM = "freeform",
    GRID = "grid",
    HORIZONTAL = "horizontal",
    LIKERT = "likert",
    VERTICAL = "vertical",
    WRAP = "wraparound",
  }


  export interface IMcqSelection {
    i: number;
    id: number;
    elementType: string;
    content: any;
  }
  export interface IEntryStateMcq extends IEntryStateScored {
    selections: Array<IMcqSelection>;
    selectedOptionId?: Set<number>, // @w
    isPathFollowed?: boolean;
    alreadyScrambled?: boolean;
  }