import * as _ from 'lodash';
import { Component, OnInit, Input, OnChanges, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LangService } from '../../core/lang.service';
import { StyleprofileService } from '../../core/styleprofile.service';
import * as ClassicEditor from 'ckeditor5-custom-build';
import { of, Subject } from 'rxjs';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { threadId } from 'worker_threads';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { EInsertType, insertAtCursor } from './util/insert-at-cursor';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TextToSpeechService } from '../text-to-speech.service';
import { INPUT_NUMBER_FORMATS } from '../../ui-item-maker/services/input-format.service';
import { ChangeEvent } from 'ckeditor5-custom-build';
import { ZoomService } from '../zoom.service';
import { PubSubTypes } from '../element-render-frame/pubsub/types';
import { ScoringTypes, QuestionState, getElementWeight, IEntryStateScored, IEntryState, IVoiceover, ElementType } from '../models';
import { IContentElementInput, InputFormat, NumberListModes, AlgebraInputModes, IEntryStateInputText, IEntryStateInputNumber, IEntryStateInputFraction, IEntryStateInputRatio, IEntryStateInputMath, IEntryStateInputNumberList } from './model';
import { CustomInteractionType } from '../element-render-custom-interaction/model';
import { OnlineOrPaperService } from '../online-or-paper.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';


const SCORING_TYPE = ScoringTypes.REVIEW;

const parseNumber = (num:string ,lang:string) =>{
  num = ''+num
  if (lang === 'fr'){
    num = num.replace( /\,/g, '.' )
  }
  return +(num.replace( /[^\-0-9.]/g, '' ));
}

const checkBlankValue = (val:any) => (val === null || val === undefined || val === '');
@Component({
  selector: 'element-render-input',
  templateUrl: './element-render-input.component.html',
  styleUrls: ['./element-render-input.component.scss']
})
export class ElementRenderInputComponent implements OnInit, OnChanges, OnDestroy {

  @Input() element:IContentElementInput;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() opinionEssayEditable: boolean;
  @ViewChild('textArea') textArea:ElementRef<HTMLElement>;
  @ViewChild('defaultText')defaultText: ElementRef<HTMLElement>;
  @ViewChild('defaultText_Paper')defaultText_Paper: ElementRef<HTMLElement>;
  @Input() textInputChange:(args:any) => void;
  @Input() fromIssueReviewer: boolean;
  @Input() cancelOverrideResponse: boolean;


  clickTriggers:Map<IContentElementInput, Subject<boolean>> = new Map();
  isShowingCapitals:boolean = false;
  frenchAccent = ["à","â","æ","ç","é","è","ê","ë","î","ï","ô","œ","ù","û","ü","«","»"]
  frenchAccentCapital = ["À",'Â','Æ','Ç','É','È','Ê','Ë','Ï','Î','Ô','Œ','Ù','Û','Ü',"«","»"]

  digits = ["0","1","2","3","4","5","6","7","8","9"];
  keyboard = {
    en: {
      digits: this.digits,
      signs: {
        decimal: '.'
      }
    },
    fr: {
      digits: this.digits,
      signs: {
        decimal: ','
      }
    }
  }
  keyboardLocal = null;
  focusedFormEl = null;

  isAlive:boolean = true;
  lastTrackedQuestionState;
  numberInput = new FormControl('');
  textInput = new FormControl('');
  fractionWholeInput = new FormControl('');
  fractionNumeInput = new FormControl('');
  fractionDenoInput = new FormControl('');
  isStarted:boolean;
  isInFocus:boolean = false;
  latexCapture = {latexInput:''};
  ratioTerms:FormControl[];
  previousInput = '';
  filterRegEx = /(<p>|<\p>|\s)/
  filterChars = ['',' ','&nbsp;','\t', '<p>','&nbsp;</p>', '&nbsp;&nbsp;</p>']
  ckEditorConfig;
  ckEditorConfig_Paper;
  Editor;
  Editor_Paper;
  displayKeyboard = 'none';
  displayNumericKeyboard = 'none';
  numberListInput:FormControl[];
  suffixListInput:FormControl[];
  numberGroupsInput:FormControl[];
  suffixGroupsInput:FormControl[];
  algebraListGiven = {};
  algebraListInput:FormControl[];
  algebraSuffixListInput:FormControl[];
  algebraGroupsGiven = {};
  algebraGroupsInput:FormControl[];
  algebraSuffixGroupsInput:FormControl[];
  isResponded: boolean;
  isConfirmFinished: boolean;
  originalText = '';

  constructor(
    private lang: LangService,
    private profile: StyleprofileService,
    private elRef: ElementRef,
    private whitelabel: WhitelabelService,
    private zoom: ZoomService,
    private text2Speech: TextToSpeechService,
    private loginGuard: LoginGuardService,
    public onlineOrPaper: OnlineOrPaperService
  ) { 
  }
  
  ngOnInit() {
    if (this.element.startingLatex){
      this.latexCapture.latexInput = this.element.startingLatex
    }
    /* math element has change even in dom */
    if (this.element.format === InputFormat.RATIO){
      this.ensureRatioTerms(); // subscription is done here
    }
    if (this.element.format === InputFormat.NUMBER_LIST){
      this.prepareNumberListMode(() => this.ensureNumberList(), () => this.ensureNumberGroups());
    }
    if (this.element.format === InputFormat.ALGEBRA){
      this.prepareAlgebraListMode(() => {}, () => this.ensureAlgebraList(), () => this.ensureAlgebraGroups());
    }
    if (!this.element.width) {
      this.element.width = 24;
    }
    if (!this.element.fixedHeight) {
      this.element.fixedHeight = 8;
    }
    this.handleNewState(); 
    this.initCkEditor();  
    this.initCkEditor_Paper();
    this.numberInput.valueChanges.subscribe( () => this.onNumberInputChange() );
    this.textInput.valueChanges.subscribe( () => this.onTextAreaInputChange() );
    this.fractionWholeInput.valueChanges.subscribe( () => this.updateState() );
    this.fractionNumeInput.valueChanges.subscribe( () => this.onNumeratorChange() );
    this.fractionDenoInput.valueChanges.subscribe( () => this.onDenominatorChange() );
    this.updateState();

    this.keyboardLocal = this.keyboard[this.lang.c()];
    this.initElementSub()
    
    this.previousInput = this.textInput.value;
    this.originalText = this.textInput.value;
    this.isConfirmFinished = false;
  }

  isPubsubElResponded: boolean //Keeps track of user interaction where default init of input is done by another Element
  isPubsubResponseTracked: boolean


  isInputLockedFromMcq = false;
  initElementSub(){
    const elementSub = this.questionPubSub.initElementSub(this.element.entryId);
    if (this.isLocked) {
      return
    }
    elementSub.subscribe((payload)=>{
      switch(this.element.format) {
        case InputFormat.NUMBER:
          if(typeof payload.data === 'object'){
            if('elementType' in payload.data){
              switch (payload.data.elementType) {
                case CustomInteractionType.PIE_CHART:
                  const {isResponded, value} = payload.data
                  this.isPubsubResponseTracked = true;
                  this.isPubsubElResponded = isResponded;
                  this.numberInput.setValue(Number(value))     
                  break;    
                case ElementType.MCQ:
                  if (payload.data.selections && payload.data.selections.length > 0){
                    this.isInputLockedFromMcq = true;
                  } else {
                    this.isInputLockedFromMcq = false;
                  }
                  break;       
                default:
                  break;
              }
            }
          } else {
            this.numberInput.setValue(Number(payload.data))
          }
          break;
        case InputFormat.TEXT:
          if (payload.type==PubSubTypes.INPUT) {
            this.textInput.setValue(payload.data)
          } 
          // else if (payload.type==PubSubTypes.TIMER) {
          //   this.transcriptTimer = payload.data
          // }
          break;
        default:
          break
      }
    })
  }

  isReadOnly(){
    if (this.opinionEssayEditable) { return false; }
    if (this.isLocked) { return true; }
    if (this.element.isDisabled) { return true; }
    if (this.loginGuard.isSaveStateCompromised) { return true; }
    if (this.isInputLockedFromMcq) return true;
    return false;
  }

  getFractionWidth() {
    const fracDenom = String(this.element.fracNumerator).length
    const fracNumer = String(this.element.fracDenominator).length
    let width = Number(this.element.maxCharFraction)
    if (width) {
      return width+1
    }
    width = (fracDenom)
    if (!width || (fracNumer && width<fracNumer)) {
      width = fracNumer
    }
    if (width) {
      return width+1
    }
    return width
  }

  prepareNumberListMode(numberListFunc, numberGroupsFunc) {
    switch (this.element.numberListMode) {
      case NumberListModes.NUMBER_LIST:
        return numberListFunc();
      case NumberListModes.NUMBER_GROUPS:
        return numberGroupsFunc();
      default:
        return this.element.numberGroups ? numberGroupsFunc() : numberListFunc();
    }
  }

  prepareAlgebraListMode(algebraFunc, algebraListFunc, algebraGroupsFunc) {
    switch (this.element.algebraInputMode) {
      case AlgebraInputModes.ALGEBRA_LIST:
        return algebraListFunc();
      case AlgebraInputModes.ALGEBRA_GROUPS:
        return algebraGroupsFunc();
      default:
        return algebraFunc();
    }
  }

  ngOnDestroy(){
    this.isAlive = false;
  }

  isInsertDown;
  ngAfterViewChecked() {
    if (this.isTextEntryShort()){
      this.resizeCkEditor();
    }
    if(this.editorObj){
      const editor = this.editorObj;
		  const view = editor.editing.view;
	    const viewDocument = view.document;

      editor.ui.focusTracker.on( 'change:isFocused', ( evt, name, isFocused ) => {
        if ( isFocused && this.element.isShowingFrenchKeys ) {
          this.displayKeyboard = 'flex'
        } else{
          this.displayKeyboard = 'none'
        }
      });

		  viewDocument.on( 'keydown', ( evt, data ) => {
        if( (data.keyCode == 9) && viewDocument.isFocused ){    
          if (!this.isInsertDown) { 
            // with white space setting to pre   
            // editor.execute('input', { text: "\t" });
            editor.execute( 'input', { text: "    " } );
            evt.stop(); // Prevent executing the default handler.
            data.preventDefault();
            view.scrollToTheSelection();
          } else {
            this.isInsertDown = false;
            evt.stop();
          }
        } else if (data.keyCode==45 || data.keyCode==18) {
          this.isInsertDown = true
        }
		  });

      viewDocument.on('keyup', ( evt, data) => {
        if (data.keyCode==45 || data.keyCode==18) {
          this.isInsertDown = false
        }
      })
      
      if(this.element.defaultText !== '') this.resizeCkEditorOnDefaultText()
      if(this.element.defaultText_Paper !== '') this.resizeCkEditorOnDefaultText_Paper()

    }
  }

  isCountdownMode(){
    return !this.whitelabel.getSiteFlag('IS_BCED') || !this.whitelabel.getSiteFlag('IS_NBED')
  }

  isPlainTextArea(){
    if(this.element.useCkEditor) return false;
    return !this.whitelabel.getSiteFlag('IS_BCED') // && !this.whitelabel.getSiteFlag('IS_EQAO')
  }

  isDualPaper(){
    return this.onlineOrPaper.isPaper && this.element.isDual
  }

  getPlaceholderDefaultPaperText() {
    if (!this.element.defaultText_Paper) return '';
    return this.element.defaultText_Paper;
  }

  getPlaceholderDefaultText() {
    if (!this.element.defaultText) return '';
    return this.element.defaultText;
  }

  initCkEditor(){
    // console.log('ckEditorData', this.ckEditorData)
    let toolbar = ['Bold', 'Italic', 'Underline',  'Highlight', 'bulletedList', 'numberedList', 'mathType' ] ;
    // toolbar = toolbar.concat(['|' ,'InsertTable']); // 'FontSize'
    toolbar = toolbar.concat(['|' ,'Undo' ,'Redo']);
    this.ckEditorConfig = {
      toolbar,
      language: this.lang.c(),
    };
    this.Editor = ClassicEditor; // CkEditor.BalloonEditor;
    window['Editor'] = this.Editor
  }

  initCkEditor_Paper(){
    let toolbar = ['Bold', 'Italic', 'Underline',  'Highlight', 'bulletedList', 'numberedList','mathType' ] ;
    toolbar = toolbar.concat(['|' ,'Undo' ,'Redo']);
    this.ckEditorConfig_Paper = {
      toolbar,
      language: this.lang.c(),
    };
    this.Editor_Paper = ClassicEditor; 
    window['Editor_Paper'] = this.Editor_Paper
  }

  confirmFinish(){
    this.isConfirmFinished = !this.isConfirmFinished
    this.updateState_ConfirmFinish();    
  }

  addFrenchCharacter(ch, el, e) {
    if(this.isReadOnly()) return;
    e.preventDefault();
    if (this.isPlainTextArea()){
      const textArea = <HTMLInputElement>this.textArea.nativeElement;
      textArea.focus();
      const {value, newPos} = insertAtCursor(textArea, ch, {type: EInsertType.BEG_END});
      this.textInput.setValue(value);
      textArea.setSelectionRange(newPos, newPos);
    }
    else{
      if (this.editorObj) {
        const viewFragment = this.editorObj.data.processor.toView(ch);
        const modelFragment = this.editorObj.data.toModel(viewFragment);
        this.editorObj.model.insertContent(modelFragment, this.editorObj.model.document.selection);
        this.editorObj.editing.view.focus()
      }
      // el.blur()
    }
  }

  onNumericInputFocusIn = (el) => {
    this.focusedFormEl = el;
    if (!this.isLocked) this.displayNumericKeyboard = 'flex';
  }
  onNumericInputFocusOut = () => this.displayNumericKeyboard = 'none';

  addKeyboardCharacter(e, ch) {
    e.preventDefault();
    
    const val = this.focusedFormEl.value ? this.focusedFormEl.value : '';
    this.focusedFormEl.setValue(`${val}${ch}`);
  }

  getGCD(num1:number, num2:number) {
    let larger = num1;
    let smaller = num2;
    if (num2>num1) {
      larger = num2;
      smaller = num1;
    }

    if (larger%smaller==0) {
      return smaller;
    }

    return this.getGCD(smaller, larger%smaller)
  }

  previousNumberInput = ""
  alreadyTriggered = false

  toggleNumberInputSign(val: String){
    if(val.charAt(0)==='-'){
      val = val.substring(1)
    } else {
      val = "-" + val;
    }
    return val;
  }

  previousNumberInputVal
  onNumberInputChange() {
    if (this.alreadyTriggered) {
      this.alreadyTriggered = false
      return
    }
    let changed = false;
    let val = this.numberInput.value;
    // Check for valid input 
    let isValid = /^-?[0-9,. ]*$/mg.test(val);
    if(!isValid){
      // Check for minus sign for toggling negative
      isValid = /^-?[0-9,. ]*-$/mg.test(val);
      if(isValid){
        val = val.slice(0, -1)
        val = this.toggleNumberInputSign(val)
      } else {
        val = this.previousNumberInput
      }
      changed = true
    } 

    if (Number(val) > this.element.maxNeumericNumber){
      val = this.previousNumberInput
      changed = true
    }

    if (this.element.isDecimalsNotAllowed) {
      let decimalChar = this.isFrench() ? ',' : '.'
      while (val && val.indexOf(decimalChar)!=-1) {
        const index = val.indexOf(decimalChar)
        val = val.substring(0,index)+val.substring(index+1)
        changed = true;
      }
    }

    if (this.element.mathCharLimit) {
      let lenOfValue = 0
      for (let ch of val) {
        if(ch !== ',' && ch !== '.' && ch!==' ') lenOfValue++
      }
      
      if (val!=undefined && val!=null && lenOfValue > this.element.mathCharLimit) {
        if (val == this.previousNumberInput) {
          val = val.toString().substring(0,this.element.mathCharLimit)
        } else {
          val = this.previousNumberInput;
        }
        changed = true;
      } else {
        this.previousNumberInput = val;
      }
    }
    if (changed) this.numberInput.setValue(val);
    // console.log('prev: ', this.previousNumberInputVal, ' now: ', this.numberInput.value)
    let canFormat = this.previousNumberInputVal?.length <  this.numberInput.value?.length; // temp for other types '0', '0.0' , '0.00' ... 
    if(this.element.inputNumFormat === 'None' || this.element.inputNumFormat === '0') canFormat = true // forcing to format
    if (this.previousNumberInputVal && canFormat){
      const obj = this.onFormatNumberValue(this.numberInput.value);
      if (obj.val==NaN || obj.val=='NaN' || !obj.val) obj.val = '' 
      if (obj.changed && this.numberInput.value!=obj.val) {
        this.alreadyTriggered = true
        val = obj.val
        this.numberInput.setValue(obj.val);
      }
    }
    this.updateState();
    this.previousNumberInputVal = val;
  }

  onNumberInputBlur() {
    this.onNumericInputFocusOut();
    this.updateState();
  }

  onNumberListInputBlur() {
    this.onNumericInputFocusOut();
    this.numberListInput.forEach(nFc => {
      const obj = this.onFormatNumberValue(nFc.value);
      if (obj.val==NaN || !obj.val) obj.val = '' 
      if (obj.changed) nFc.setValue(obj.val);
      this.updateState();
    });
  }

  onNumberGroupsInputBlur() {
    this.onNumericInputFocusOut();
    this.numberGroupsInput.forEach(nFc => {
      const { val, changed } = this.onFormatNumberValue(nFc.value);
      if (changed) nFc.setValue(val);
      this.updateState();
    });
  }

  onFormatNumberValue(val) {
    let changed = true;
    // let val = value ? value.replace(/[^0-9.]/g, '') : null;  #reformatting in input-format service.
    // in case the first character is the minus sign for negative numbers
    if (val == '-' && (this.element.acceptEnFrNumericFormats || this.lang.c()=='en' || this.lang.c()=='fr')) return {val: '-', changed};
    if (isNaN(parseFloat(val))) return { val: '', changed };
    if (this.element.inputNumFormat && !this.element.acceptEnFrNumericFormats) {
      const formattedVal = INPUT_NUMBER_FORMATS[this.element.inputNumFormat](val, this.isFrench());
      if (formattedVal != val) {
        val = String(formattedVal);
      }
    }
    // When accepting multiple formats => formatting is disabled => remove the leading zeros.
    if(this.element.acceptEnFrNumericFormats && val.length > 1){      
      if(val[0] === '0'){
        val = !isNaN(val[1]) ? val.substring(1) : val;
      }
    }
    return { val, changed };
  }

  getWidthNum(field:string, valField:string) {
    const buffer = 1
    if (this.element[field] && this.element[field]>0) {
      return Number(this.element[field])+Number(buffer)
    } else if (this.element[valField] != undefined && this.element[valField] != null){
      return Number(this.element[valField].length)+Number(buffer)
    } else {
      if (field=='value') return 5;
      else return 4
    }
  }

  previousNumerator = ''
  previousDenominator = ''
  onNumeratorChange() {
    this.onFractionChange(true)
    this.updateState();
  }

  onDenominatorChange() {
    this.onFractionChange(false)
    this.updateState();
  }

  onFractionChange(isNumerator: boolean) {
    let maxChar = this.element.maxCharFraction;
    let val = this.fractionNumeInput
    let prevVal = this.previousNumerator
    if (!isNumerator) {
      maxChar = this.element.maxCharFraction
      val = this.fractionDenoInput
      prevVal = this.previousDenominator
    }
    // Check for validation + Charlimit
    if (!this.isFractionInputValid(val, maxChar)) val.setValue(prevVal);
    
    if (isNumerator) {
      this.previousNumerator = val.value
    } else {
      this.previousDenominator = val.value
    }
  }

  isFractionInputValid(input: FormControl, maxChar){
    let isValid = !input.value.includes('.');
    if (isValid) {
       const strVal = String(input.value)
       for (let i = 0;i<strVal.length;i++) {
         const c = strVal.charAt(i)
         if (c<'0' || c>'9') {
           isValid = false
           break;
         }
       }
    }
    if(isValid && maxChar) return input.value.length <= maxChar;
    return isValid;
  }


  onTextAreaInputChange(){
    if (this.isLimitByWords(true)) {
      const str = this.textInput.value || '';
      const words = str.split(this.filterRegEx);
      let numWords = 0;
      let maxWords = +this.element.maxWords+this.getExtraWordsOverLimit();
      words.forEach((word)=>{
        if (!this.filterChars.includes(word)) numWords++;
      })
      if (numWords>maxWords){
        this.textInput.setValue(this.previousInput);
      }
      else{ 
        this.previousInput = this.textInput.value
      }
    } 
    else if(this.element.selectedLimitType == 'char'){
      let charArray = this.getCharArray();
      if(charArray.length > this.element.maxChars){
        this.textInput.setValue(this.previousInput)
      } else {
        this.previousInput = this.textInput.value
      }
    }
    this.updateState();
    if(this.opinionEssayEditable){
      this.textInputChange(this.textInput.value)
    }
  }

  getCharArray() {
    let str = this.textInput.value || '';
    if(str) str = str.toString()
    let charArray = str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '');
    return charArray;
  }

  getRemainingChars(){
    return this.element.maxChars - this.getCharArray().length 
  }

  ensureRatioTerms(){
    if (!this.ratioTerms && this.element.ratioTerms){
      this.ratioTerms = [];
      this.element.ratioTerms.forEach(() => {
        const fc = new FormControl()
        this.ratioTerms.push(fc)
        fc.valueChanges.subscribe( (v) => {
          this.updateState() 
        });
      });
    }
  }

  ensureNumberList() {
    if (!this.numberListInput && this.element.numberList){
      this.numberListInput = [];
      this.suffixListInput = [];
      this.element.numberList.forEach((v) => {
        const fc = new FormControl()
        this.numberListInput.push(fc)
        this.suffixListInput.push(new FormControl(v.suffix));
        fc.valueChanges.subscribe( () => this.getInputNumberListState());
      });
    }
  }

  ensureNumberGroups() {
    if (!this.numberGroupsInput && this.element.numberGroups){
      this.numberGroupsInput = [];
      this.suffixGroupsInput = [];
      const [ group ] = this.element.numberGroups;
      group.forEach(v => {
        const fc = new FormControl()
        this.numberGroupsInput.push(fc)
        this.suffixGroupsInput.push(new FormControl(v.suffix));
        fc.valueChanges.subscribe( () => this.getInputNumberGroupsState());
      })
    }
  }

  ensureAlgebraList() {
    if (!this.algebraListInput && this.element.algebraList){
      this.algebraListInput = [];
      this.algebraSuffixListInput = [];
      this.element.algebraList.forEach((v) => {
        const fc = new FormControl()
        this.algebraListInput.push(fc)
        this.algebraSuffixListInput.push(new FormControl(v.suffix));
        fc.valueChanges.subscribe( () => this.getInputAlgebraListState());
      });
    }
  }

  ensureAlgebraGroups() {
    if (!this.algebraGroupsInput && this.element.algebraGroups){
      this.algebraGroupsInput = [];
      this.algebraSuffixGroupsInput = [];
      const [ group ] = this.element.algebraGroups;
      group.forEach(v => {
        const fc = new FormControl()
        this.algebraGroupsInput.push(fc)
        this.algebraSuffixGroupsInput.push(new FormControl(v.suffix));
        fc.valueChanges.subscribe( () => this.getInputAlgebraGroupsState());
      })
    }
  }

  ngOnChanges(){
    if(this.fromIssueReviewer && !this.opinionEssayEditable && this.cancelOverrideResponse){
      this.textInput.setValue(this.originalText);
    }
    if (this.lastTrackedQuestionState !== this.questionState){
      this.lastTrackedQuestionState = this.questionState;
      if (this.questionState){
        this.handleNewState();
      }
    }
  }

  isFormatTypeNumber(){ return this.element.format === InputFormat.NUMBER }
  isFormatTypeFraction(){ return this.element.format === InputFormat.FRACTION }
  isFormatTypeAlgebra(){ return this.element.format === InputFormat.ALGEBRA && this.element.algebraInputMode === AlgebraInputModes.ALGEBRA_INPUT || this.element.latex && !this.element.algebraList }
  isFormatTypeAlgebraList(){ return this.element.format === InputFormat.ALGEBRA && this.element.algebraInputMode === AlgebraInputModes.ALGEBRA_LIST || this.element.algebraList }
  isFormatTypeAlgebraGroups(){ return this.element.format === InputFormat.ALGEBRA && this.element.algebraInputMode === AlgebraInputModes.ALGEBRA_GROUPS || this.element.algebraGroups }
  isFormatTypeRatio(){ return this.element.format === InputFormat.RATIO }
  isFormatTypeTextShort(){ return (this.element.format === InputFormat.TEXT) && (this.isTextEntryShort())   }
  isFormatTypeTextLong(){ return (this.element.format === InputFormat.TEXT) && (!this.isTextEntryShort())  }
  isFormatTypeForm() { return (this.element.format === InputFormat.FORM); }
  isFormatNumberListTypeForm() { return (this.element.format === InputFormat.NUMBER_LIST && this.element.numberListMode === NumberListModes.NUMBER_LIST || this.element.numberList); }
  isFormatNumberGroupsTypeForm() { return (this.element.format === InputFormat.NUMBER_LIST && this.element.numberListMode === NumberListModes.NUMBER_GROUPS || this.element.numberGroups); }

  isTextEntryShort(){
    return false;
    // return (this.element.maxChars && this.element.maxChars < 20);
  }

  getEntryState(){
    let state = undefined
    switch (this.element.format){
      case InputFormat.TEXT:     state = this.getInputTextState();break;
      case InputFormat.NUMBER:   state = this.getInputNumberState();break;
      case InputFormat.FRACTION: state = this.getInputFractionState();break;
      case InputFormat.RATIO:    state = this.getInputRatioState();break;
      case InputFormat.ALGEBRA:  
        state = this.prepareAlgebraListMode(() => this.getInputAlgebraState(), () => this.getInputAlgebraListState(), () => this.getInputAlgebraGroupsState());break;
      case InputFormat.NUMBER_LIST:
        state = this.prepareNumberListMode(() => this.getInputNumberListState(), () => this.getInputNumberGroupsState());break;
      default:
        state = <IEntryStateInputText>{
          type: 'input-longtext',
          isCustomGrading: true,
          isStarted: false,
          isFilled: false,
          isResponded: false,
          isPaperFormat: false,
          str: '{ERROR}',
        }
    }
    if (this.element.isDisabled) {
      state["isScoringDisabled"] = true
    }
    return state
  }

  getEntryState_ConfirmFinish(){
    let state = undefined
    switch (this.element.format){
      case InputFormat.TEXT: state = this.getInputTextState_ConfirmFinish();break;
      default:
        state = <IEntryStateInputText>{
          type: 'input-longtext',
          isCustomGrading: true,
          isStarted: false,
          isFilled: false,
          isResponded: false,
          isPaperFormat: false,
          str: '{ERROR}',
        }
    }
    if (this.element.isDisabled) {
      state["isScoringDisabled"] = true
    }
    return state
  }


  isLimitByWords(maxWord?:boolean) {
    let isInputLimit = this.profile.getTextInputLimit();
    if(maxWord) return isInputLimit && this.element.maxWords && this.getExtraWordsOverLimit()>=0 && this.isLimitTypeWord();
    return isInputLimit;
  }

  isLimitTypeWord() {
    return this.element.selectedLimitType == 'word' || !this.element.selectedLimitType;
  }

  getExtraWordsOverLimit() {
    if (this.element.isSetHardLimitToZero) {
      return 0;
    }
    const wordsOverLimit = this.profile.getExtraWordsOverLimit();
    return wordsOverLimit;
  }

  getInputNumberState(){
    const value = this.numberInput.value;
    const isFilled = !checkBlankValue(value);
    const weight = getElementWeight(this.element);
    let isCorrect = this.element.acceptEnFrNumericFormats 
      ? this.isEnFrFormattedNumberCorrect(this.numberInput.value, this.element.value) 
      : this.isNumberCorrect(this.numberInput.value, this.element.value); 
    this._isResponded(isFilled);
    return <IEntryStateInputNumber>{
      type: 'input-number',
      isCorrect,
      isStarted: this.isStarted || isFilled,
      isFilled,
      isResponded: this.isResponded,
      value,
      score: isCorrect ? weight : 0,
      weight,
      scoring_type: ScoringTypes.REVIEW, 
    }
  }

  private _isResponded(isFilled: boolean) {
    if (!this.isResponded && isFilled)
      if(this.isPubsubResponseTracked){
        this.isResponded = !!this.isPubsubElResponded;
      } else {
        this.isResponded = true;
      }
  }

  getInputFractionState(){
    let wholenumber = this.fractionWholeInput.value;
    let numerator = this.fractionNumeInput.value;
    let denominator = this.fractionDenoInput.value;
    let isFilled = true;
    if (this.element.isMixedNumber && checkBlankValue(wholenumber)){ isFilled = false; }
    if (checkBlankValue(numerator)){ isFilled = false; }
    if (checkBlankValue(denominator)){ isFilled = false; }
    let isCorrect = false;
    const weight = getElementWeight(this.element);
    let wholeGiven:number = 0;
    let wholeInput:number = 0;
    const lang = this.lang.c();
    let numeInput:number = parseNumber(numerator, lang);
    let denoInput:number = parseNumber(denominator, lang);
    let numeGiven:number = parseNumber(this.element.fracNumerator, lang);
    let denoGiven:number = parseNumber(this.element.fracDenominator, lang);
    if (this.element.isMixedNumber){
      wholeGiven = parseNumber(this.element.fracWholeNumber, lang);
      wholeInput = parseNumber(wholenumber, lang);
    }
    if (this.element.isStrictSimplified){
      isCorrect = (wholeGiven === wholeInput) 
               && (numeGiven === numeInput)
               && (denoGiven === denoInput);
    }
    else{
      if (denominator === 0 && denoGiven === 0){
        isCorrect = true;
      }
      else {
        const approx = (wholeGiven + (numeGiven/denoGiven)) - (wholeInput + (numeInput / denoInput));
        if (Math.abs(approx) < 0.000001){
          isCorrect = true;
        }
      }
    }
    if (this.element.isReductionForced && numeInput && denoInput && this.getGCD(numeInput, denoInput) > 1) {
      isCorrect = false;
    }

    this._isResponded(isFilled);
    return <IEntryStateInputFraction>{
      type: 'input-fraction',
      isCorrect,
      isStarted: this.isStarted || isFilled,
      isFilled,
      isResponded: this.isResponded,
      wholenumber,
      numerator,
      denominator,
      score: isCorrect ? weight : 0,
      weight,
      scoring_type: ScoringTypes.REVIEW, 
    }
  }
  getInputRatioState(){
    let isFilled = true;
    this.ensureRatioTerms();
    const lang = this.lang.c();
    const terms = this.ratioTerms.map(termFc => {
      const value = termFc.value;
      if (checkBlankValue(value)){
        isFilled = false;
      }
      return parseNumber(value, lang);
    })
    let isCorrect = false;
    const weight = getElementWeight(this.element);
    
    const given = this.element.ratioTerms.map(val => parseNumber(val, lang) ) || [];
    const input = terms || [];
    if (given.length === 1){ // rare case?
      if (given[0] === input[0]){
        isCorrect = true;
      }
    }
    else {
      let scalingFactor = 1;
      if (!this.element.isStrictLowestTerms){
        scalingFactor = input[0] / given[0];
      }
      let isAllMatched = true; // until contra case found
      for (let i=0; i<given.length; i++){
        if ( Math.abs(given[i] - input[i]*scalingFactor) > 0.00001){
          isAllMatched = false;
        }
      }
      isCorrect = isAllMatched;
    }

    this._isResponded(isFilled);
    return <IEntryStateInputRatio>{
      type: 'input-ratio',
      isCorrect,
      isStarted: this.isStarted || isFilled,
      isFilled,
      isResponded: this.isResponded,
      terms,
      score: isCorrect ? weight : 0,
      weight,
      scoring_type: ScoringTypes.REVIEW, 
    }
  }
  
  getInputAlgebraState(){
    const latex = this.latexCapture.latexInput;
    const isFilled = !checkBlankValue(latex);
    this._isResponded(isFilled);
    return <IEntryStateInputMath>{
      type: 'input-algebra',
      isCustomGrading: true,
      isStarted: this.isStarted || isFilled,
      isFilled,
      isResponded: this.isResponded,
      latex,
      score: 0,
      weight: getElementWeight(this.element),
      scoring_type: ScoringTypes.MANUAL, 
    }
  }

  getInputAlgebraListState(){
    let isFilled = true;
    this.ensureAlgebraList();

    const givenValues = this.algebraListInput ? this.algebraListInput.map(nFc => {
      if (checkBlankValue(nFc.value)) isFilled = false;
      return nFc.value ? nFc.value : '';
    }) : [];

    this.algebraListGiven = { algebraListInput: this.algebraListInput };
    this._isResponded(isFilled)
    return <IEntryStateInputNumberList>{
      type: 'input-algebra_list',
      isCustomGrading: true,
      isStarted: this.isStarted || isFilled,
      isFilled,
      isResponded: this.isResponded,
      values: givenValues,
      score: 0,
      weight: getElementWeight(this.element),
      scoring_type: ScoringTypes.MANUAL, 
    }
  }

  getInputAlgebraGroupsState(){
    let isFilled = true;
    this.ensureAlgebraGroups();

    const givenValues = this.algebraGroupsInput ? this.algebraGroupsInput.map(nFc => {
      if (checkBlankValue(nFc.value)) isFilled = false;
      return nFc.value ? nFc.value : '';
    }) : [];
    
    this.algebraGroupsGiven = { algebraGroupsInput: this.algebraGroupsInput };
    this._isResponded(isFilled);
    return <IEntryStateInputNumberList>{
      type: 'input-algebra_list',
      isStarted: this.isStarted || isFilled,
      isFilled,
      isResponded: this.isResponded,
      values: givenValues,
      score: 0,
      weight: getElementWeight(this.element),
      scoring_type: ScoringTypes.MANUAL, 
    }
  }

  isTextInputValueEmpty() {
    if (this.textInput && !this.textInput.value) return true;
    return this.textInput.value.length === 0;
  }

  getInputTextState(){
    const str = this.textInput.value;
    const isFilled = this.element.isAnswerNotNecessary || !checkBlankValue(str);
    this._isResponded(isFilled);
    return <IEntryStateInputText>{
      type: 'input-longtext',
      isCustomGrading: true,
      isStarted: this.isStarted || isFilled,
      isResponded: this.isResponded,
      isFilled,
      isPaperFormat: this.onlineOrPaper.isPaper,
      str,
      score: 0,
      weight: getElementWeight(this.element),
      scoring_type: ScoringTypes.MANUAL, 
    }
  }
  getInputTextState_ConfirmFinish(){
    const str = this.textInput.value;
    let isFilled: boolean;
    this.isConfirmFinished? isFilled = true : isFilled = false;
    this._isResponded(isFilled);

    if(this.isResponded && !isFilled)
      this.isResponded = false

    return <IEntryStateInputText>{
      type: 'input-longtext',
      isCustomGrading: true,
      isStarted: this.isStarted || isFilled,
      isResponded: this.isResponded,
      isFilled,
      isPaperFormat: this.onlineOrPaper.isPaper,
      str,
      score: 0,
      weight: getElementWeight(this.element),
      scoring_type: ScoringTypes.MANUAL, 
    }
  }
  getInputNumberListState(){
    let isFilled = true;
    this.ensureNumberList();

    const givenValues = this.numberListInput ? this.numberListInput.map(nFc => {
      if (checkBlankValue(nFc.value)) isFilled = false;
      return nFc.value ? nFc.value : '';
    }) : [];

    const weight = getElementWeight(this.element);
    const correctValues = this.element.numberList.map(n => n.value);
    const givenValuesSorted = this.sortArray1ByArray2(givenValues, correctValues);
    const isCorrect = givenValuesSorted ? !!givenValuesSorted.every((givenV, i) => {
      return this.element.acceptEnFrNumericFormats 
            ? this.isEnFrFormattedNumberCorrect(givenV, correctValues[i]) 
            : this.isNumberCorrect(givenV, correctValues[i]); 
    }) : false;

    this._isResponded(isFilled);
    return <IEntryStateInputNumberList>{
      type: 'input-number_list',
      isCorrect,
      isStarted: this.isStarted || isFilled,
      isFilled,
      isResponded: this.isResponded,
      values: givenValues,
      score: isCorrect ? weight : 0,
      weight,
      scoring_type: ScoringTypes.AUTO, 
    }
  }

  getInputNumberGroupsState(){
    let isFilled = true;
    this.ensureNumberGroups();

    const givenValues = this.numberGroupsInput ? this.numberGroupsInput.map(nFc => {
      if (checkBlankValue(nFc.value)) isFilled = false;
      return nFc.value ? nFc.value : '';
    }) : [];

    const weight = getElementWeight(this.element);
    let isCorrect = false;
    if (givenValues) {
      isCorrect = !!this.element.numberGroups.find(group => {
        return !!group.every((set, setIndex) => {
          return this.element.acceptEnFrNumericFormats 
            ? this.isEnFrFormattedNumberCorrect(givenValues[setIndex], set.value) 
            : this.isNumberCorrect(givenValues[setIndex], set.value); 
        })
      })
    }

    this._isResponded(isFilled);
    return <IEntryStateInputNumberList>{
      type: 'input-number_list',
      isCorrect,
      isStarted: this.isStarted || isFilled,
      isFilled,
      isResponded: this.isResponded,
      values: givenValues,
      score: isCorrect ? weight : 0,
      weight,
      scoring_type: ScoringTypes.AUTO, 
    }
  }

  sortArray1ByArray2 = (array1, array2) => {
    if (!array1 || !array2) return null;
    return array1.slice().sort((a, b) => {
      a = a.replace(/[^0-9.]/g, '');
      b = b.replace(/[^0-9.]/g, '');
      return array2.indexOf(a) - array2.indexOf(b);
    });
  };

  getStyle() {
    const style = {}
    if (!this.element.isNoInvertOnHiContrast && this.text2Speech.isHiContrast) {
      style["filter"] = "invert(0.8)"
    }
    return style
  }

  isHighContrast() {
    if (!this.element.isNoInvertOnHiContrast && this.text2Speech.isHiContrast) {
      return true;
    }
    return false;
  }

  getRemainingCharacters(){
    const input = this.textInput.value || '';
    return this.element.maxChars - input.length;
  }

  resizeCkEditorOnDefaultText(){
    let height,minWidth;

    const resizeEditor = (height,minWidth) => {
      this.editorObj.editing.view.change( writer => {
        writer.setStyle( 'height', height, this.editorObj.editing.view.document.getRoot() );
        writer.setStyle( 'min-width', minWidth, this.editorObj.editing.view.document.getRoot() );
      });
    }

    if(!this.textInput.value && this.defaultText){
      height = this.defaultText.nativeElement.offsetHeight + 55 +'px'
      minWidth = this.defaultText.nativeElement.offsetWidth + 16 +'px'  
    } else {
      height = '';
      minWidth = ''
    }
    
    resizeEditor(height,minWidth)    
  }

  resizeCkEditorOnDefaultText_Paper(){
    let height,minWidth;
    const resizeEditor = (height,minWidth) => {
      this.editorObj.editing.view.change( writer => {
        writer.setStyle( 'height', height, this.editorObj.editing.view.document.getRoot() );
        writer.setStyle( 'min-width', minWidth, this.editorObj.editing.view.document.getRoot() );
      });
    }

    if(this.defaultText_Paper){
      height = this.defaultText_Paper.nativeElement.offsetHeight + 55 +'px'
      minWidth = this.defaultText_Paper.nativeElement.offsetWidth + 16 +'px'  
    } else {
      height = '';
      minWidth = ''
    }
    
    resizeEditor(height,minWidth)    
  }

  resizeCkEditor = _.throttle(() => {
    const ckeditor = this.elRef.nativeElement.getElementsByTagName("ckeditor")[0];
    if (ckeditor) {
      const el = ckeditor.getElementsByTagName('div').item(12)
      if (el) {
        if (this.element.fixedHeight && this.element.isFixedHeight) {
          el.style.height = this.element.fixedHeight + 'em';
        } else {
          el.style.height = '';
        }
      }
    }
  }, 5000);

  getWords() {
    const input = this.textInput.value || '';
    const words = input.split(this.filterRegEx);
    // console.log(words)
    let length = words.length;
    
    words.forEach((word)=>{
      if (this.filterChars.includes(word)) length--;
    })
    return length;
  }
  getRemainingWords() {

    return this.element.maxWords - this.getWords();
  }

  isNumberCorrect(givenValue, correctValue){
    if (givenValue !== null && givenValue != ''){
      const lang = this.lang.c();
      let valInput = parseNumber(givenValue, lang);
      let valExpected = parseNumber(correctValue, lang);
      let tolerance = this.element.roundingTolerance || 0.00000001
      if (this.element.roundingTolerancePercentageBased) {
        tolerance = valExpected * (parseFloat(this.element.roundingTolerance) / 100);
      }
      if (Math.abs(valExpected - valInput) <= tolerance){
        return true
      }
    }
    return false;
  }

  isEnFrFormattedNumberCorrect = (givenValue, correctValue) =>{
    let isCorrect = false;
    if (givenValue !== null && givenValue !== undefined && givenValue != ''){
      const lang = this.lang.c();
      let valInputs = this.getGivenVariations(givenValue);
      let valExpected = parseNumber(correctValue, lang);
      let tolerance = this.element.roundingTolerance || 0.00000001
      if (this.element.roundingTolerancePercentageBased) {
        tolerance = valExpected * (parseFloat(this.element.roundingTolerance) / 100);
      }
      isCorrect = valInputs.some(v => Math.abs(valExpected - v) <= tolerance)
    }
    return isCorrect;
  }

  getGivenVariations = (num) => {
    num = num.replace( /[^0-9.,]/g, '' );

    let variations = [num];
    let updatedNum = num;

    if (num.match(/\,/g) || num.match(/\./g)) {
      if (num.match(/\./g)) {
        updatedNum = num.replace( /\,/g, '' );
        variations = [...variations, updatedNum];

        updatedNum = num.replace( /\,/g, '' );
        updatedNum = updatedNum.replace( /\./g, ',' );
        variations = [...variations, updatedNum];
      }

      if (num.match(/\,/g)) {
        updatedNum = num.replace( /\./g, '' );
        variations = [...variations, updatedNum];

        updatedNum = num.replace( /\./g, '' );
        updatedNum = updatedNum.replace( /\,/g, '.' );
        variations = [...variations, updatedNum];
      }
    }

    return variations;
  }

  updateState = _.throttle(() => {
    if (!this.isAlive){ return; }
    if (this.isLocked){ return; }
    if (!this.questionState || !this.questionState[this.element.entryId]){ return; }
    this.questionState[this.element.entryId] = this.getEntryState();
    if(this.questionPubSub){
      this.questionPubSub.allPub({entryId: this.element.entryId, type: PubSubTypes.UPDATE_VALIDATOR, data: {}})
    }
  }, 500);

  updateState_ConfirmFinish = _.throttle(() => {
    if (!this.isAlive){ return; }
    if (this.isLocked){ return; }
    if (!this.questionState || !this.questionState[this.element.entryId]){ return; }
    this.questionState[this.element.entryId] = this.getEntryState_ConfirmFinish();
    if(this.questionPubSub){
      this.questionPubSub.allPub({entryId: this.element.entryId, type: PubSubTypes.UPDATE_VALIDATOR, data: {}})
    }
  }, 500);

  onCkEditorUpdate({editor}:ChangeEvent ){
    this.textInput.setValue(editor.getData());
    //this.onTextAreaInputChange();
    if (editor.getData() != this.textInput.value) {
      // Setting the data reset caret position to startnode we don't want that
      // editor.data.set(this.textInput.value)
      editor.execute('delete')
    }
    this.updateState();
  }

  editorObj
  onCkEditorReady(e:any){
    try {
      //disable spellcheck
      if (!this.editorObj) this.editorObj = e;
      e.editing.view.change(writer => {
        writer.setAttribute( 'spellcheck', 'false', e.editing.view.document.getRoot() )
      })
      // console.log(this.textInput.value)
      e.data.set(this.textInput.value)
      if(this.isLocked) this.editorObj.enableReadOnlyMode(this.getCkEditorElementId())
    }
    catch(e){
      console.warn('fixed state restoration')
    }
  }

  getCkEditorElementId() {
    return `ck-editor-instance-${this.element.entryId }`
  }

  isBlankText(){
    const str = this.textInput.value;
    return !str ||  (str === '')
  }

  isFrench() {
    return this.lang.c() === 'fr'
  }

  getZoomVal(){
    return this.zoom.getScreenShrinkZoom();
  }

  getInputFontSize(){
    const zoomVal = this.getZoomVal();
    if (zoomVal > 0 && zoomVal < 1) {
      return zoomVal;
    }
  }

  ensureState(){
    if (this.questionState){
      const entryId = this.element.entryId;
      if (!this.questionState[entryId]){
        let entryState:IEntryStateScored = {
          type: 'input',
          isCorrect: false,
          isStarted: false,
          isFilled: false,
          isResponded: false,
          score: 0,
          weight: getElementWeight(this.element),
          scoring_type: SCORING_TYPE, 
        }
        this.questionState[entryId] = entryState;
      }
    }
  }

  handleNewState(){
    if (this.questionState){
      const entryState:IEntryState = this.questionState[this.element.entryId];
      if (entryState){
        this.isStarted = entryState.isStarted;
        this.injectStateToDom(entryState)
      }
      else{
        this.ensureState();
      }
    }
  }
  injectStateToDom(state:IEntryState){
    switch (this.element.format){
      case InputFormat.TEXT: return this.injectTextState(<IEntryStateInputText> state);
      case InputFormat.NUMBER: return this.injectNumberState(<IEntryStateInputNumber> state);
      case InputFormat.ALGEBRA: 
        return this.prepareAlgebraListMode(
          () => this.injectAlgebraState(<IEntryStateInputMath> state), 
          () => this.injectAlgebraListState(<IEntryStateInputNumberList> state),
          () => this.injectAlgebraGroupsState(<IEntryStateInputNumberList> state),
        );
      case InputFormat.FRACTION: return this.injectFractionState(<IEntryStateInputFraction> state);
      case InputFormat.RATIO: return this.injectRatioState(<IEntryStateInputRatio> state);
      case InputFormat.NUMBER_LIST: 
        return this.prepareNumberListMode(() => this.injectNmberListState(<IEntryStateInputNumberList> state), () => this.injectNmberGroupsState(<IEntryStateInputNumberList> state));
    }
  }
  injectNumberState(state:IEntryStateInputNumber){
    this.numberInput.setValue(state.value);
  }
  injectFractionState(state:IEntryStateInputFraction){
    this.fractionWholeInput.setValue(state.wholenumber);
    this.fractionNumeInput.setValue(state.numerator);
    this.fractionDenoInput.setValue(state.denominator);
  }
  injectRatioState(state:IEntryStateInputRatio){
    this.ensureRatioTerms();
    if (state.terms){
      state.terms.forEach( (value, i) => {
        const termFc = this.ratioTerms[i];
        if (termFc){
          termFc.setValue(value);
        }
      })
    }
  }
  injectAlgebraState(state:IEntryStateInputMath){
    this.latexCapture.latexInput = state.latex;
  }
  injectAlgebraListState(state:IEntryStateInputNumberList){
    this.ensureAlgebraList();
    if (state.values){
      state.values.forEach( (value, i) => {
        if (this.algebraListInput[i]){
          this.algebraListInput[i].setValue(value);
        }
      })
    }
  }
  injectAlgebraGroupsState(state:IEntryStateInputNumberList){
    this.ensureAlgebraGroups();
    if (state.values){
      state.values.forEach( (value, i) => {
        if (this.algebraGroupsInput[i]){
          this.algebraGroupsInput[i].setValue(value);
        }
      })
    }
  }
  injectTextState(state:IEntryStateInputText){
    // console.log('injectTextState', state.str)
    // this.ckEditorData = state.str;
    this.textInput.setValue(state.str);
  }
  injectNmberListState(state:IEntryStateInputNumberList){
    this.ensureNumberList();
    if (state.values){
      state.values.forEach( (value, i) => {
        if (this.numberListInput[i]){
          this.numberListInput[i].setValue(value);
        }
      })
    }
  }
  injectNmberGroupsState(state:IEntryStateInputNumberList){
    this.ensureNumberGroups();
    if (state.values){
      state.values.forEach( (value, i) => {
        if (this.numberGroupsInput[i]){
          this.numberGroupsInput[i].setValue(value);
        }
      })
    }
  }
  isVoiceoverEnabled(){
    return this.text2Speech.isActive;
  }
  getClickTrigger(element){

    let trigger = this.clickTriggers.get(element);
    if (!trigger){
      trigger = new Subject();
      this.clickTriggers.set(element, trigger);
    }

    return trigger;
  }

  getTextVoiceURL(element:IContentElementInput) {
    if (element?.voiceover?.url) {
      return element.voiceover.url
    }
  }

  getButtonVoiceURL(element:IVoiceover) {
    if (element?.url) {
      return element.url
    }
  }

  textEditorMouseEnter() {
    this.getClickTrigger(this.element).next(true);
  }

  buttonMouseEnter() {
    this.getClickTrigger(this.element.buttonVoiceOver).next(true);
  }
}
