import { API_ADDRESS_LOCAL } from "../../api/api-addr/l";
import { IContextData } from "./common";

export const MBED:IContextData = {
  id: 'MBED',
  homepageRoute: '/en/general/login/false',
  brandName: 'Vretta eAssessment',
  logo: '',
  footer: null,
  apiAddress: (hostname:string) => {
    switch(hostname){
      case 'localhost': 
        // return API_ADDRESS_LOCAL;
      default: 
        return 'https://api-eassessment.vretta.com';
    }
  },
  siteFlags: {
    'IS_MBED': true, 
    'INST_MNG_TT': true,
    'TEST_CTRL_REPORT_EXPLR': true,
    'TEST_CTRL_DATA_QUERY': true,
  },
  siteText: {
    copyright: ' © Vretta Inc. 2020',
    supportEmail: 'support@vretta.com',
    supportPhone: '',
    my_inst: 'My School',
    my_inst_admin: 'You are the **administrator** of this school.',
    my_inst_manage_acct: 'Manage Teachers+',
    currentLogo: 'https://www.vretta.com/logo.svg',
    student_ident: 'Student ID',
  },
  hosts: [
      'mbed.vretta.com',
      'localhost:4200'
  ],
  defaultTimezone: 'America/Vancouver',
}
