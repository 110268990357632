import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, getFrontendDomain } from 'src/app/api/auth.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { RoutesService } from 'src/app/api/routes.service';
import { LangService } from 'src/app/core/lang.service';
import { EStyleProfile, StyleprofileService } from 'src/app/core/styleprofile.service';
import { TextToSpeechService } from 'src/app/ui-testrunner/text-to-speech.service';
import { EAnimTrigger, IMapConfig, ISubSessionAttemptDef } from '../pj-map/pj-map.component';
import { objectToNumMap } from '../student-util';
import jMap from '../../../../data/pj-maps/j-map.json'
import pMap from '../../../../data/pj-maps/p-map.json'
import { AccountType } from 'src/app/constants/account-types';
import { StudentG9DashboardService } from '../student-g9-dashboard.service';
import { StudentAssistiveTechService } from '../student-assistive-tech.service';
import { CALC_FRAME_ID } from 'src/app/ui-testrunner/widget-calculator/widget-calculator.component';
import { ISubSessionStateInfo, StudentG9ConnectionService } from '../student-g9-connection.service';
import { BcTechnicalGuidesComponent } from 'src/app/ui-whitelabel-bc-landing/bc-technical-guides/bc-technical-guides.component';
import { info } from 'console';
import { ASSESSMENT } from 'src/app/ui-teacher/data/types';
import { ISsAttemptSlugMap } from '../types';
import { parseAttemptLoadErrorMessage } from '../view-student-live-assess/util/parse-warning';
import { PageModalController, PageModalService } from 'src/app/ui-partial/page-modal.service';

enum View {
  WELCOME = 'WELCOME',
  HOME = 'HOME',
  MAP = 'MAP',
  COMPLETION = 'COMPLETION',
  RESULTS = 'RESULTS',
  TOOL = 'TOOL'
}

const ENABLE_STYLE_PROFILE_OVERRIDE = true;

interface ITrConfig {
  asmtFmrk?:any,
  questions?:any,
  testForm?:any,
  isLoaded?: boolean,
  errorMessage?: string,
  questionTitleMap?: any,
  sectionIndex?: number,
  questionIndex?: number,
  questionScores?: Map<number, boolean>
  answerKey?: any,
  styleProfile?: EStyleProfile,
  doNotScore?: boolean,
  goHomeOnSubmit?: boolean,
  isQuestionnaire?: boolean,
  attemptId?: number,
  asmtLabel?: string,
  doNotSave?: boolean,
  sectionsAllowed?: number[],
  moduleId?: number
}

const LOCK_STATUS_INTERVAL = 10000;

export const G6_SUBMIT_BG_COLOR = '#4df63a';
export const G3_SUBMIT_BG_COLOR = '#8DFF80';
@Component({
  selector: 'pj-page-switcher',
  templateUrl: './pj-page-switcher.component.html',
  styleUrls: ['./pj-page-switcher.component.scss']
})
export class PjPageSwitcherComponent implements OnInit, OnDestroy {
  pageModal: PageModalController;
  currentView: string;
  isG6: boolean;
  linear: number;
  isPublic: boolean = false;
  testTakerName; //undefined for public sample test
  currentSubSession?: ISubSessionAttemptDef
  refreshSubElTrigger;
  numTimesOffScreen: number = 0;

  ssStateChangeSub;

  animTriggerEvents = new Map();

  View = View;
  Object = Object;

  trConfig : {[key:string]: ITrConfig} = {
    'TOOL': {
      doNotScore: true,
      doNotSave: true,
      goHomeOnSubmit: true,
      styleProfile: EStyleProfile.GR9
    }
  }

  sampleSubSessionDefs : ISubSessionAttemptDef[] = [
    {
      slug: 'lang_session_a',
      sections: [0],
      twtdar_order: 0,
      styleProfile: EStyleProfile.OSSLT,
    },
    {
      slug: 'lang_session_b',
      sections: [1],
      twtdar_order: 0,
      styleProfile: EStyleProfile.OSSLT,
    },
    {
      slug: 'math_stage_1',
      sections: [0],
      twtdar_order: 1,
      styleProfile: EStyleProfile.GR9
    }, 
    {
      slug: 'math_stage_2',
      sections: [1],
      twtdar_order: 1,
      styleProfile: EStyleProfile.GR9
    }
  ]

  //For testing operational map  
  //   sampleSubSessionDefs : ISubSessionAttemptDef[] = [
  //   {
  //     slug: 'lang_session_a',
  //     sections: [0],
  //     twtdar_order: 0,
  //     styleProfile: EStyleProfile.OSSLT,
  //   },
  //   {
  //     slug: 'lang_session_b',
  //     sections: [1],
  //     twtdar_order: 0,
  //     styleProfile: EStyleProfile.OSSLT,
  //   },
  //   {
  //     slug: 'lang_session_c',
  //     sections: [0],
  //     twtdar_order: 0,
  //     styleProfile: EStyleProfile.OSSLT,
  //   },
  //   {
  //     slug: 'lang_session_d',
  //     sections: [1],
  //     twtdar_order: 0,
  //     styleProfile: EStyleProfile.OSSLT,
  //   },
  //   {
  //     slug: 'math_stage_1',
  //     sections: [0],
  //     twtdar_order: 1,
  //     styleProfile: EStyleProfile.GR9
  //   }, 
  //   {
  //     slug: 'math_stage_2',
  //     sections: [1],
  //     twtdar_order: 1,
  //     styleProfile: EStyleProfile.GR9
  //   },
  //   {
  //     slug: 'math_stage_3',
  //     sections: [0],
  //     twtdar_order: 1,
  //     styleProfile: EStyleProfile.GR9
  //   }, 
  //   {
  //     slug: 'math_stage_4',
  //     sections: [1],
  //     twtdar_order: 1,
  //     styleProfile: EStyleProfile.GR9
  //   },
  //   {
  //     slug: 'session_q',
  //     sections: [0],
  //     twtdar_order: 1,
  //     styleProfile: EStyleProfile.GR9
  //   }
  // ]
  

  subSessionDefs : ISubSessionAttemptDef[] = [];

  ssAttemptSlugMap : ISsAttemptSlugMap = {};
  
  isLoggedIn:boolean = false;
  userSub;

  map: IMapConfig;
  lockStatusInterval;
  isLocked: number = -1;

  isLoadingSubSession: boolean = false;
  isAltVersionRequestsPending: boolean = false;
  isAltVersionRequestsApproved: boolean = false;
  isAltVersionRequestsOperationalSent: boolean = false;
  testSessionId: number;
  asmtSlug: ASSESSMENT;
  interval;
  seenStudentOffScreenWarning: boolean = false;
  isWindowFocused: boolean = true;
  activeSubSessionId: number;
  student:any;
  currentStudentPosition = { 
    stageIndex: null, 
    questionCaption: null 
  };

  constructor(private route: ActivatedRoute, 
    public lang: LangService,
    private auth: AuthService,
    private routes: RoutesService,
    private styleprofile: StyleprofileService,
    private loginGuard: LoginGuardService,
    public textToSpeech: TextToSpeechService,
    private dash: StudentG9DashboardService,
    private assisTech: StudentAssistiveTechService,
    public studentG9Connection: StudentG9ConnectionService,
    private router: Router,
    private pageModalService: PageModalService,
    ) { }


  ngOnInit(): void {
    this.ssStateChangeSub = this.studentG9Connection.subSessionStateChange.subscribe( (info: ISubSessionStateInfo) => {
      if(!info) {
        return;
      }
      if(this.ssAttemptSlugMap && this.ssAttemptSlugMap[info.subSessionSlug]) {
        const ssAttempt = this.ssAttemptSlugMap[info.subSessionSlug];
        if(info.available) {
          if (this.activeSubSessionId) {
            this.goTo(View.MAP);
          }
          this.updateActiveSubSession(ssAttempt.id);
        } else if(!info.available && this.activeSubSessionId === ssAttempt.id) {
          this.updateActiveSubSession(null);
          if(Object.keys(this.trConfig).includes(this.currentView) && this.currentView !== View.TOOL) {
            this.goTo(View.MAP);
          }
        } else if (info.isSubmitting && !ssAttempt.is_submitted) {
          ssAttempt.is_submitted = 1;
        }
      }
    })

    //this.isPublic = this.route.snapshot.data['isPublic'];
    const clientDomain = getFrontendDomain()
    //const sampleTestDomain = ["http://localhost:4200/", "https://d3d9vqrpii4nuo.cloudfront.net/"]
    const sampleTestDomain = ["https://d3d9vqrpii4nuo.cloudfront.net/", "https://d3k4cvve5iw7mr.cloudfront.net/"]
    this.isPublic = sampleTestDomain.indexOf(clientDomain) > -1

    if(this.isPublic) {
      this.initPublicSampleTest();
    } else if(!this.userSub){
      this.userSub = this.auth.user().subscribe((userInfo) => {
        if(userInfo) {
          if(userInfo.accountType === AccountType.STUDENT) {
            this.student = {
              schClassGroupId: userInfo.sch_class_group_id,
              firstName: userInfo.first_name.toUpperCase(),
              lastName: userInfo.last_name.toUpperCase(),
              oenOrSasn: userInfo.studentOenOrSasn,
              isSasnLogin: userInfo.isSasnLogin,
            }
            this.verifyStudentModalStart();

            if(this.auth.u().sch_class_group_type !== 'EQAO_G3' && this.auth.u().sch_class_group_type !== 'EQAO_G6') {
              this.loginGuard.disabledPopup('txt_student_no_asmt');
              return;
            }
            this.isG6 = this.auth.u().sch_class_group_type === 'EQAO_G6';
            this.isPublic = false;
            this.linear = this.auth.u().linear;
            this.loadTrConfigs().then(() => {
              this.dash.loadAssessments(true).then((res) => {
                const sessions = res[0].classes_sessions;
                if (sessions?.length) {
                  let session = sessions[0];
                  if (!session) {
                    this.loginGuard.disabledPopup('txt_student_no_asmt');
                    return;
                  }
                  this.asmtSlug = session.slug;
                  this.testSessionId = session.test_session_id;
                  this.isLoggedIn = true;
                  this.isAltVersionRequestsPending = session.isAltVersionRequestsPending == 1
                  this.isAltVersionRequestsApproved = session.isAltVersionRequestsApproved == 1
                  this.isAltVersionRequestsOperationalSent = session.isAltVersionRequestsOperationalSent == 1
                  this.goTo(View.WELCOME);
                } else {
                  this.loginGuard.disabledPopup('txt_student_no_asmt');
                  return;
                }
              })

            });
          } else {
            this.goToLogin();
          }
        } else if(this.auth.hasAutoLoggedOut) {
          this.goToLogin();
        }
      })
    }

    this.dash.init();
    // this.checkLockStatus();
    if(!this.pageModal) {
      this.pageModal = this.pageModalService.defineNewPageModal();
    }
    window.onbeforeunload = () => this.studentG9Connection.disconnect();
  }

  goToLogin() {
    this.router.navigate(['/en/login-router-st']);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  cModal() {
    return this.pageModal.getCurrentModal();
  }

  verifyStudentModalStart(){
    if(!this.pageModal) {
      this.pageModal = this.pageModalService.defineNewPageModal();
    }
    const config: any = {};
    this.pageModal.newModal({
      type: '',
      config,
      finish: config => this.verifyStudentModalFinish()
    });
  }

  verifyStudentModalFinish(){
    this.pageModal.closeModal();
  }
  

  getIsStudentVerified(){
    const isStudentVerified = this.auth.getCookie("studentLastNameInput")
    if(isStudentVerified) return true;
    return false;
  }

  checkLockStatus() {
    this.lockStatusInterval = setInterval(async () => {
      const res = await this.auth.apiFind(this.routes.STUDENT_ACTIVE_SUBSESSION_STATUS, {query: {test_session_id: this.testSessionId}});
      if (res && res.length > 0) {
        const {isLocked, activeSSId} = res[0];
        // on init
        if (this.isLocked === -1) {
          this.isLocked = isLocked;
        } 
        // check for if the lock status changed, and if it did, student must be moved back to map
        else {
          if (this.isLocked != isLocked) {
            // locked and subsession id on test attempt is null
            if (isLocked && !activeSSId) {
              this.updateActiveSubSession(null);
            } else {
              // unlocking subsession
              this.updateActiveSubSession(activeSSId);
            }
            this.goTo(View.MAP);
          }
        }
      }
    }, LOCK_STATUS_INTERVAL);
  }

  updateActiveSubSession(id?: number) {
    this.activeSubSessionId = id;
    this.refreshSubElTrigger = !this.refreshSubElTrigger;
    if(id == null) {
      const twtdarOrders = this.subSessionDefs?.map( def => `${def.twtdar_order}` );
      if(twtdarOrders?.includes(this.currentView)) // we are in an assessment. Bring us out of it.
      {
        this.goTo(View.MAP);
      }
    }
  }

  initPublicSampleTest() {
    this.isG6 = this.route.snapshot.data['isG6'];
    this.linear = this.route.snapshot.data['linear'];
    this.loadAllSampleQuestions().then(() => {
      this.isLoggedIn = true;
      this.goTo(View.WELCOME);
      this.initSampleMap();
    });
  }

  initSampleMap() {
    this.map = <IMapConfig>(this.isG6 ? jMap : pMap);
    this.initSsAttemptSlugMap(this.sampleSubSessionDefs);
  }

  initSsAttemptSlugMap(subSessionDefs: ISubSessionAttemptDef[]) {
    for(const def of subSessionDefs) {
      this.ssAttemptSlugMap[def.slug] = def;
    }
  }

  ngOnDestroy() {
    if(this.userSub) {
      this.userSub.unsubscribe();
    }

    if(this.ssStateChangeSub) {
      this.ssStateChangeSub.unsubscribe();
    }

    clearInterval(this.interval);
    this.cleanUpSoftLock();
  }

  cleanUpSoftLock() {
    document.removeEventListener('fullscreenchange', this.setStudentSoftLock,true);
    window.onblur = () => {}
  }

  initMap() {
    return this.auth.apiGet(this.routes.STUDENT_MAP, this.testSessionId).then((res) => {
      //TODO: check the linear flag on test attempts to set linear.
      const {map, subSessions} = res;
      
      this.activeSubSessionId = null;

      for(const subSession of subSessions) {
        if(subSession.active_sub_session_id != null) {
          this.activeSubSessionId = subSession.active_sub_session_id;
        }
        if(!this.trConfig[`${subSession.twtdar_order}`]) {
          this.trConfig[`${subSession.twtdar_order}`] = {};
        }
      }

      this.map = map;
      this.initSsAttemptSlugMap(subSessions);
    })
  }

  goTo(view: string) {
    if(!this.trConfig[view]) {
      clearInterval(this.interval);
    }
    if((view === View.MAP && !this.isPublic) || (view === View.RESULTS && this.isSample())) {
      return this.initMap().then(() => {
        this.currentView = view;
      }).catch(
        e => {
          this.loginGuard.disabledPopup('txt_student_no_asmt');
        }
      );
    } 
    else if(this.trConfig[view] && this.trConfig[view].styleProfile){
      this.styleprofile.setStyleProfile(this.trConfig[view].styleProfile, true);
    }
    else if (ENABLE_STYLE_PROFILE_OVERRIDE && this.currentSubSession?.slug?.includes('lang_session') ){
      this.styleprofile.setStyleProfile(EStyleProfile.OSSLT, true);
    }
    this.currentView = view;
  }

  getAsmtLabel() {
    return !this.isG6 ? 'primary' : 'junior';
  }

  logAsmtStart(){
    this.auth.apiCreate(this.routes.LOG, {
      slug: 'PJ_SAMPLE_TEST_START',
      data: { 
        asmtLabel: this.getAsmtLabel(), 
        lang: this.lang.c(), 
        isAnonymous: true,
        uid: this.auth.getUid()
        // testSessionId: this.testSessionId, 
      }
    });
  }

  getSampleItemSetId(testId: string) {
    switch(testId) {
      case View.TOOL:
        return !this.isG6 ? 376 : 404;
      case '0':  //Lang twtdar order
        if(!this.isG6) {
          if(!this.linear) {
            return this.lang.c() === 'en' ? 392 : 395;
          } else {
            return this.lang.c() === 'en' ? 393 : 396;
          }
        } else {
          if(!this.linear) {
            return this.lang.c() === 'en' ? 399 : 397;
          } else {
            return this.lang.c() === 'en' ? 401 : 398;
          }
        }
      case '1': //Math twtdar order
        if(!this.isG6) {
          return !this.linear ? 381 : 380;
        } else {
          return !this.linear ? 378 : 379;
        }
    }
  }

  async loadSampleQuestions(testId: string) {
    const itemSetId = this.getSampleItemSetId(testId);
    let trConfig = this.trConfig[testId];
    return this.auth
    .apiGet(this.routes.ANON_SAMPLE_TEST_DESIGN_FORM, itemSetId, {query: {lang: this.lang.c()}})
    .then ( (res:{testFormData:any, framework:string}) => {
      trConfig.asmtFmrk = JSON.parse(res.framework);
      // this.genFromModuleMap(this.sessions[0].sections_allowed);
      trConfig.questions = objectToNumMap(res.testFormData.questionDb),
      trConfig.testForm = {
        currentTestDesign: {
            ... res.testFormData,
            questionDb: null,
        },
        questionStates: {},
        testLang: res.testFormData.lang,
        questionSrcDb: trConfig.questions,
      };
      if(!trConfig.doNotScore) {
        trConfig.questionScores = new Map(); //This is used to show checkmark / 'X' next to each entry in the results page, but hasn't been working anywhere for a while. Still used for MPT so keeping it for now.
      }
      trConfig.isLoaded = true;
    })
    .catch(e => {
      trConfig.isLoaded = true;
      trConfig.errorMessage = 'This sample test is not currently available.'
    })
  }

  async loadTrConfigs() {
    return Promise.all(Object.keys(this.trConfig).map( testId => {
      return this.loadSampleQuestions(testId);
    }))
  }

  async loadAllSampleQuestions(){
    this.logAsmtStart();
    for(const def of this.sampleSubSessionDefs) {
      this.trConfig[`${def.twtdar_order}`] = {
      }
    }
    return this.loadTrConfigs();
  }

  saveQuestionResponseForTestId(testId:string) {
    const trConfig = this.trConfig[testId];
    return (data: any) => {
      if(this.isPublic || trConfig.doNotSave) {
        //do nothing for public sample test
        return Promise.resolve();
      }
      return this.auth.apiCreate(
        this.routes.STUDENT_SESSION_QUESTION,
        {
          ...data,
          test_attempt_id: trConfig.attemptId,
        },
        this.configureQueryParams()
      );
    }
  }

  submitTestWTestId(testId: string) {
    return (skipPost?: boolean) => {
      if(this.trConfig[testId].goHomeOnSubmit) {
        //Note: This won't work with post-amble pages, but doing this for now
        this.goTo(View.HOME);
        return Promise.resolve();
      } else if(this.isPublic) {
        this.auth.apiCreate(this.routes.LOG, {
          slug: 'G9_SAMPLE_TEST_SUBMIT',
          data: {
            asmtLabel: this.getAsmtLabel(),
            lang: this.lang.c(),
            states: this.trConfig[testId].testForm.questionStates,
            uid: this.auth.getUid(),
            testId: testId
            //testSessionId: this.testSessionId
          }
        })
        return Promise.resolve();
      } else {
        //Actually submit the test.
        return this.auth.apiPatch(
          this.routes.STUDENT_SESSION_QUESTION, // bad name ... to do
          this.testSessionId,
          { test_attempt_id:  <any> this.trConfig[testId].attemptId, subsession_id: this.currentSubSession.id, subsession_slug: this.currentSubSession.slug, subsession_order: this.currentSubSession.order },
          this.configureQueryParams()
        ).then(r => {
          this.logTestSubmit(testId);
          this.studentG9Connection.updateStudentPosition({submitConfig: {submitted: true, subSessionIndex: this.currentSubSession.order}});
          this.markSubSessionSubmitted(this.currentSubSession);
          if(!skipPost) {
            this.postSubmit();
          }
        });
      }
    }
  }

  markSubSessionSubmitted(ss: ISubSessionAttemptDef) {
    ss.is_submitted = 1;
    if(!this.animTriggerEvents.get(EAnimTrigger.ON_SS_COMPLETE)) {
      this.animTriggerEvents.set(EAnimTrigger.ON_SS_COMPLETE, new Set()); 
    }
    this.animTriggerEvents.get(EAnimTrigger.ON_SS_COMPLETE).add(ss.slug);
  }

  postSubmit = () => {
    return this.goTo(View.MAP);
  }

  postSubmit_SampleTest = () => {
    return this.goTo(View.RESULTS);
  }

  logTestSubmit(testId) {
    const trConfig = this.trConfig[testId];
    this.auth.apiCreate(this.routes.LOG, {
      slug: 'STUDENT_TEST_SUBMIT',
      data: {
        uid: this.auth.getUid(),
        testSessionId: this.testSessionId,
        asmtLabel: trConfig.asmtLabel,
        lang: this.lang.c(),
        states: trConfig.testForm.questionStates,
        questions: trConfig.testForm.questionSrcDb,
        withTeacher: this.route.snapshot.queryParams.withTeacher,
      }
    })
  }

  getCustomConfirmTestDialogData(testId) {
    const trConfig = this.trConfig[testId];
    return trConfig.isQuestionnaire ? {
      text: 'tr_questionnaire_submit',
      confirmMsg: 'lbl_yes',
      cancelMsg: 'lbl_no'
    } : null;
  }

  parseTags(tagList){
    const tagReference = {};
    if (tagList){
      tagList.forEach(tag => {
        tagReference[tag.slug] = true;
      })
    }
    return tagReference;
  }

  initInterval(attemptId: number){
    this.interval = setInterval(() => {
      if (!this.auth.checkLoggedIn()) {
        clearInterval(this.interval);
        return;
      }
      this.auth.apiPatch(this.routes.STUDENT_SESSION, this.testSessionId, {test_attempt_id:attemptId},this.configureQueryParams())
    }, 30*1000)
  }

  configureQueryParams() {
    if (this.auth.u()) {
      return {
        query: {
          schl_class_group_id: this.auth.u().sch_class_group_id
        }
      }
    }
    return null;
  }

  async forceFullScreen() {
    let elem = document.documentElement;
    let methodToBeInvoked = elem.requestFullscreen || elem['mozRequestFullscreen']
      ||
      elem['msRequestFullscreen'];
    if (methodToBeInvoked) {
      try {
        await methodToBeInvoked.call(elem);
      }
      catch (err) {
        this.setStudentSoftLock();
      }
    }
  }

  detectFullScreen() {
    document.addEventListener('fullscreenchange', (event) => {
      if (!document.fullscreenElement) {
        this.setStudentSoftLock();
      }
    });
  }

  setStudentSoftLock = (event?) => {
    if (this.studentG9Connection) {
      this.studentG9Connection
        .updateStudentPosition({
          stageIndex: this.currentStudentPosition.stageIndex,
          questionCaption: this.currentStudentPosition.questionCaption,
          softLock: 1,
          numTimesOffScreen: this.numTimesOffScreen
        });
      if (!this.seenStudentOffScreenWarning) {
        this.seenStudentOffScreenWarning = true;
        this.loginGuard.confirmationReqActivate({
          caption: 'msg_student_navigate_popup',
          btnCancelConfig: {
            hide: true
          }
        })
      }
    }
  }

  windowFocused = (event?) => {
    this.isWindowFocused = true;
  }

  windowBlurred = (event?) => {
    this.isWindowFocused= false;
    let target = event?.target;
    if(target?.length && target[0].frameElement?.id === CALC_FRAME_ID) {
      target[0].onblur = this.windowBlurred;
      target[0].onfocus = this.windowFocused;
    }

    setTimeout(()=>{
      if(!this.isWindowFocused) {
        this.numTimesOffScreen++;
        this.setStudentSoftLock(event);
      }
    }, 5);
  }

  enterSubSession(subSessionSlug: string) {
    if(this.isLoadingSubSession) {
      return;
    }
    if(this.isPublic) {
      this.currentSubSession = this.ssAttemptSlugMap[subSessionSlug];
      const testId = `${this.currentSubSession.twtdar_order}`;
      const trConfig = this.trConfig[testId];
      trConfig.sectionsAllowed = this.currentSubSession.sections;
      this.styleprofile.setStyleProfile(this.currentSubSession.styleProfile, true);
      this.goTo(`${this.currentSubSession.twtdar_order}`);
    } else {
      this.dash.loadAttempt(this.testSessionId).then(res => {
        const payload = res[0]
        const attemptId = payload.attemptId;
        const studentLastNameInput = this.auth.getCookie("studentLastNameInput");
        const isStudentVerified = this.auth.getCookie("isStudentLastNameVerified");
        if(!isStudentVerified) {
          this.auth.apiCreate(this.routes.VERIFY_STUDENT, {studentLastNameInput, attemptId}, this.configureQueryParams())
          this.auth.setCookie("isStudentLastNameVerified", '1', (1/12));  // expire in 2 hrs
        }
        const testId = `${payload.attempt_twtdar_order}`;
        const trConfig = this.trConfig[testId];
        trConfig.sectionsAllowed = payload.sections_allowed;
        trConfig.sectionIndex = payload.section_index;
        trConfig.questionIndex = payload.question_index;
        trConfig.moduleId = payload.module_id;
        trConfig.asmtFmrk = payload.framework ? JSON.parse(payload.framework) : {};
        const testDesign = payload.testDesign;
        trConfig.questions = objectToNumMap(testDesign.questionDb),
        trConfig.testForm = {
          currentTestDesign: {
            ...testDesign,
            questionDb: null
          },
          questionStates: payload.questionStates,
          testLang: testDesign.lang,
          questionSrcDb: trConfig.questions,
        };
        trConfig.questionScores = new Map(); //This is used to show checkmark / 'X' next to each entry in the results page, but hasn't been working anywhere for a while. Still used for MPT so keeping it for now.
        const tagReference = this.parseTags(trConfig.asmtFmrk.tags);
        trConfig.isQuestionnaire = tagReference['QUESTIONNAIRE'];

        if(tagReference['PJ_LANG']) {
          this.styleprofile.setStyleProfile(EStyleProfile.OSSLT, true);
        } else if(tagReference['PJ_MATH']) {
          this.styleprofile.setStyleProfile(EStyleProfile.GR9, true)
        }

        trConfig.attemptId = payload.attemptId;
        trConfig.asmtLabel = payload.asmtLabel;

        clearInterval(this.interval);
        this.initInterval(payload.attemptId)

        if (payload.assistiveTech) {
          this.assisTech.setStudentAssistiveTechStatus(true);
        } else {
          this.assisTech.setStudentAssistiveTechStatus(false);
        }
        if ((this.auth.activeKioskPassword || payload.assistiveTech || this.dash.getSchoolSoftLockEnable()) && !payload.isSebMode) {
          if(!this.auth.activeKioskPassword){
           this.forceFullScreen();
          } 
          this.detectFullScreen()
          window.onblur = this.windowBlurred;
          window.onfocus = this.windowFocused;
        } else {
          this.cleanUpSoftLock();
        }
        trConfig.isLoaded = true;
        this.isLoadingSubSession = false;
        this.currentSubSession = this.ssAttemptSlugMap[subSessionSlug];
        this.goTo(`${this.currentSubSession.twtdar_order}`);
      }).catch(e => {
        this.isLoadingSubSession = false;
        //this.loginGuard.disabledPopup('Error: Could not load test');
        const {mode, isUnknown} = parseAttemptLoadErrorMessage(e.message);
        this.loginGuard.disabledPopup(this.lang.tra(mode));
      });
    }
  }

  // keep track of current section index, so the result page can hide other sections
  showSectionsSectionIndex
  completeSubSession(data) {
    if(this.isPublic) {
      const currSubSession = this.currentSubSession;
      if(data.sectionIndex === currSubSession.sections[currSubSession.sections.length - 1] ) {
        this.markSubSessionSubmitted(currSubSession);
        this.goTo(View.RESULTS);
      }
    }
    else if(this.isSample()) {
      this.showSectionsSectionIndex = this.trConfig[+this.currentView].sectionIndex;
      const currSubSession = this.currentSubSession;
      this.markSubSessionSubmitted(currSubSession);
    }
  }

  getShowSections(){
    if (this.isSample()){
      return [this.showSectionsSectionIndex]
    }
    return this.currentSubSession.sections
  }

  isSample() {
    return this.isPublic || this.asmtSlug?.includes('SAMPLE');
  }

  showTtsToggle() {
    return !Object.keys(this.trConfig).includes(this.currentView); 
  }

  toggleAudioButton(){
    this.textToSpeech.toggle();
    console.log(this.textToSpeech.isActive, "audio toggle");
  }

  currViewHasDarkBg() {
    return this.isG6 && this.currentView !== View.RESULTS;
  }

  setStudentPosition($event){
    this.currentStudentPosition.stageIndex = $event.stageIndex
    this.currentStudentPosition.questionCaption = $event.questionCaption
  }

  getSectionIndexInit(testId: string) {
    return this.trConfig[testId].sectionIndex ?? 0;
  }
}
