import { IContentElement, IEntryStateScored, INumberTolerance, IScoredResponse, IVoiceover } from "../models";

export interface IContentElementInput extends IContentElement, IScoredResponse {
  acceptEnFrNumericFormats?: boolean;
  algebraGroups?: AlgebraList[][];
  algebraInputMode?: string;
  algebraList?: AlgebraList[];
  alternativeValues?: IContentElementInput[];
  defaultText?: string;
  defaultTextAlignment?: string;
  defaultTextColour?: string;
  fixedHeight?: number;
  format: InputFormat;
  formElements?: IContentElement[];
  fracDenominator?: string;
  fracNumerator?: string;
  fracWholeNumber?: string;
  inputNumFormat?: string;
  isAllowEquivalent?: boolean;
  isAnswerNotNecessary?: boolean;
  isDecimalsNotAllowed?: boolean;
  maxNeumericNumber?: number;
  isDisabled?:boolean; 
  isFixedHeight?: boolean;
  isMixedNumber?: boolean;
  isReductionForced?: boolean;
  isSetHardLimitToZero?: boolean;
  isShowingFrenchKeys?: boolean;
  isShowingNumericKeyboard?: boolean;
  isShowingNumericKeyboardWithDecimal?: boolean;
  isShowingWordCount?: boolean;  
  isStrictLowestTerms?: boolean;
  isStrictSimplified?: boolean;
  isToolbarAutoHide?: boolean;
  latex?: string;
  mathCharLimit?: number;
  maxCharDenominator?: number;
  maxCharFraction?: number; // deprecated?
  maxCharNumerator?: number;
  maxChars?: number;
  maxWords?: number;
  minChars?: number;
  minWords?: number;
  numberGroups?: NumberList[][];
  numberList?: NumberList[];
  numberListMode?: string;
  prefix?: string;
  ratioTerms?: string[];
  roundingTolerance?: string;
  roundingTolerancePercentageBased?: boolean;
  selectedLimitType?: string;
  startingLatex?: string;
  suffix?: string;
  tolerances?: INumberTolerance[];
  value?: string;
  width?: number;

  isDual?: boolean;
  defaultText_Paper?: string;
  defaultTextAlignment_Paper?: string;
  defaultTextColour_Paper?: string;
  buttonText?: string;
  buttonVoiceOver?: IVoiceover;
  useCkEditor?: boolean
}

  export interface IEntryStateInputNumber extends IEntryStateScored {
    value: string;
  }
  export interface IEntryStateInputMath extends IEntryStateScored {
    latex: string;
  }
  export interface IEntryStateInputText extends IEntryStateScored {
    str: string;
    isPaperFormat: boolean;
  }
  export interface IEntryStateInputFraction extends IEntryStateScored {
    wholenumber: number;
    numerator: number;
    denominator: number;
    reducedValue?: number;
  }
  export interface IEntryStateInputRatio extends IEntryStateScored {
    terms: number[];
  }

  export interface IEntryStateInputNumberList extends IEntryStateScored {
    values: string[];
  }

  export enum InputFormat {
    ALGEBRA = "algebra",
    FORM = "form",
    FRACTION = "fraction",
    NUMBER = "number",
    NUMBER_LIST = "number_list",
    RATIO = "ratio",
    TEXT = "text",
  }

  export enum AlgebraInputModes {
    ALGEBRA_INPUT = "algebraInput",
    ALGEBRA_LIST = "algebraList",
    ALGEBRA_GROUPS = "algebraGroups",  
  }

  export enum NumberListModes {
    NUMBER_LIST = "numberList",
    NUMBER_GROUPS = "numberGroups",
  }

  export interface NumberList {
    value?: string;
    suffix?: string;
    values?: string[];
  }
  
  export interface AlgebraList {
    value?: string;
    suffix?: string;
  }

  export const inputEditInfo = {
    editExcludeFields: ['alternativeValues'],
    editTextFields: ['defaultText']
  }