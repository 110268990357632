import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { RoutesService } from '../api/routes.service';
import { AuthService } from '../api/auth.service';
import { ITestSession, ITestSessionDashboardInfo, ITestSessionBookingInfo } from '../api/models/db/test-sessions.schema';
import { DemoDataService, IAvailableSession, IBooking } from './demo-data.service';
import { BehaviorSubject } from 'rxjs';
import { LangService } from '../core/lang.service';

export interface ISessionRestrictionPatch {
  isHidden: boolean,
  isAccessCodeProtected: boolean,
  accessCode:string
}

@Injectable({
  providedIn: 'root'
})
export class AvailableSessionsService {

  private _myTestSessions:IAvailableSession[];
  private myTestSessions:BehaviorSubject<IAvailableSession[]> = new BehaviorSubject(null);
  private lastRequest: { instit_group_id: number };
  private timezone = moment.tz.guess();
  
  constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private lang: LangService,
    // cannot point to Inst as it would make a circular reference
  ) { }

  public sub(){
    return this.myTestSessions;
  }

  // isRefreshInProgress:boolean = true;
  public refresh = _.debounce(()=>{
    this.myTestSessions.next(null);
    if (this.lastRequest){
      this.loadAssociatedTestSessions(this.lastRequest.instit_group_id)
    }
  }, 500, {leading: true, trailing: false})

  public setInstitGroupId(instit_group_id:number){
    this.lastRequest = {instit_group_id};
  }

  private isSuperWatcher:boolean;
  public toggleSuperWatcher(state:boolean){
    this.isSuperWatcher = state;
  }


  private renderSessionFindApiRoute(){
    if (this.isSuperWatcher){
      return this.routes.TEST_ADMIN_TEST_SESSIONS_ALL;
    }
    else{
      return this.routes.TEST_ADMIN_TEST_SESSIONS_MY_UPCOMING;
    }
  }

  private renderSessionSingleApiRoute(){
    if (this.isSuperWatcher){
      return this.routes.TEST_ADMIN_TEST_SESSIONS_SINGLE;
    }
    else{
      return this.routes.TEST_ADMIN_TEST_SESSIONS_MY_SINGLE;
    }
  }

  private constructPermissionsParams(query?:any){
    if (!this.lastRequest){ console.warn('availSess constructPermissionsParams early exit'); return; }
    const {instit_group_id} = this.lastRequest;
    return {
      query:{ 
        ... query,
        instit_group_id, 
      }
    }
  }

  public loadSingleSession(sessionId:number, isPrior?: boolean){
    const query = {};
    if(isPrior) query['is_prior'] = true;
    
    const params = this.constructPermissionsParams(query);
    return this.auth
      .apiGet(
        this.renderSessionSingleApiRoute(),
        sessionId,
        params
      )
      .then((testSession:ITestSessionDashboardInfo) => {
        // console.log('testSession', testSession)
        return this.sanitizeDbSession(testSession);
      })
  }

  public loadAssociatedTestSessions(instit_group_id:number){
    this.setInstitGroupId(instit_group_id);
    return this.auth
      .apiFind(
        this.renderSessionFindApiRoute(),
        this.constructPermissionsParams()
      )
      .then((testSessions:ITestSessionDashboardInfo[]) => {
        this._myTestSessions = testSessions.map((testSession, i) => this.sanitizeDbSession(testSession, i))
        this.myTestSessions.next(this._myTestSessions);
      })
  }

  private sanitizeDbSession(testSession:ITestSessionDashboardInfo, sessionIndex:number=-1){
    const dateTimeStart = moment(testSession.date_time_start);
    // const isStartingNow = dateTimeStart.diff(moment(), 'hours') < 12;
    const bookings = (testSession.bookings_list || []).map( this.sanitizeBookingEntry );
    const waitlist = (testSession.waitlist_list || []).map( this.sanitizeBookingEntry );
    if (dateTimeStart && dateTimeStart.diff(moment(), 'hours') < 12){
      testSession.isTestDay = true;
    }
    let testWindowTitle;
    try {
      const titles = JSON.parse(testSession.test_window_title);
      testWindowTitle = titles[this.lang.c()];
    }
    catch(e){}
    // console.log(testSession, testSession.isTestDay)
    return <IAvailableSession>{
      __isStartingSoon: testSession.isTestDay,
      __num: sessionIndex+1,
      id: testSession.id,
      testWindowId: testSession.test_window_id,
      test_session_group_id: testSession.test_session_group_id,
      testWindowTitle,
      room: testSession.room,
      campusBuilding: testSession.campus_building,
      address: testSession.address,
      city: testSession.city,
      province: testSession.province, 
      postalCode: testSession.postal_code,
      phoneNumber: testSession.phone_number,
      facultyId: testSession.instit_group_id, // TO DO : rename from facultyId to instGroupId
      delivery_format: testSession.delivery_format,
      dateTimeStart: moment.tz(testSession.date_time_start, this.timezone),
      dateTimeEnd: moment.tz(testSession.date_time_end, this.timezone),
      capacity: testSession.capacity,
      isAccessCodeEnabled: !!testSession.is_access_code_enabled,
      accessCode: testSession.access_code,
      isHidden: !!testSession.is_hidden,
      isClosed: !!testSession.is_closed,
      status: testSession.status,
      inivigilatorId: testSession.uid,
      inivigilatorFirstName: testSession.first_name,
      inivigilatorLastName: testSession.last_name,
      inivigilatorEmailName: testSession.contact_email,
      invitationCode: testSession.invitation_code,
      videostream_link: testSession.videostream_link,
      videostream_password: testSession.videostream_password,
      bookings,
      waitlist,
      bookingsCount: bookings.length || testSession.bookings,
      waitlistCount: waitlist.length || testSession.waitlist,
      time_ext_m: testSession.time_ext_m,
      test_window_duration_m: testSession.test_window_duration_m,
      is_paused: !!testSession.is_paused,
      is_expired: !!testSession.is_expired,
      is_soft_lock_disabled: testSession.is_soft_lock_disabled,
      open_for_booking: testSession.open_for_booking == 1 ? true : false,
      show_student_progress: testSession.show_student_progress == 1 ? true : false,
      show_online_offline: testSession.show_online_offline == 1 ? true : false,
      disable_softlock: testSession.disable_softlock == 1 ? true : false,
      deadline_lockdown_days: testSession.deadline_lockdown_days,
      paired_session_id: testSession.paired_session_id,
    }
  }

  sanitizeBookingEntry = (booking:ITestSessionBookingInfo) : IBooking => {
    const firstName = booking.first_name;
    const lastName = booking.last_name;
    const langReq = booking.lang_req;
    return {
      name: firstName+' '+lastName,
      firstName,
      lastName,
      langReq,
      nameSortable: lastName+firstName,
      timestamp: moment(booking.created_on),
      uid: booking.uid,
      email: booking.contact_email,
      reqTransferTimestamp: booking.reqTransferTimestamp,
      is_paused: booking.is_paused,
      is_soft_lock_disabled: booking.is_soft_lock_disabled,
      is_closed: booking.is_closed
    }
  }

  renderInvigDisplay(firstName:string, lastName:string, email:string){
    let str = firstName;
    if (lastName){
      str = str + ' ' + lastName
    }
    if (email){
      str = str + ' ('+email+')';
    }
    return str;
  }
  
  renderInvigDisplayFromSession(session:IAvailableSession){
    if (session){
      const firstName = session.inivigilatorFirstName;
      const lastName = session.inivigilatorLastName;
      const email = session.inivigilatorEmailName;
      return this.renderInvigDisplay(firstName, lastName, email);
    }
  }

  getSessionById(sessionId:number){
    for (let i=0; i<this._myTestSessions.length; i++){
      let session = this._myTestSessions[i];
      if (session.id === sessionId){
        return session;
      }
    }
  }
  
  patchSessionRestriction(sessionId:number, formData){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_RESTRICTIONS, 
        sessionId, 
        {
          ... formData,
        },
        this.constructPermissionsParams()
      )
  }

  patchSessionCapacity(sessionId:number, newCapacity:number){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_CAPACITY, 
        sessionId, 
        {
          newCapacity,
        },
        this.constructPermissionsParams()
      )
  }

  patchSessionStatus(sessionId:number, newStatus:string){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_STATUS_UPDATE, 
        sessionId, 
        {
          newStatus,
        },
        this.constructPermissionsParams()
      )
  }

  patchBookingDeadline(sessionId:number, newBookingDeadline:string){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_BOOKING_DEADLINE, 
        sessionId, 
        {
          newBookingDeadline,
        },
        this.constructPermissionsParams()
      )
  }

  patchPairedSession(sessionId:number, newPairedSession:string){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_PAIRED_SESSION, 
        sessionId, 
        {
          newPairedSession,
        },
        this.constructPermissionsParams()
      )
  }

  patchBookingAvailability(sessionId:number, newBookingAvailability:boolean){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_BOOKING_AVAILABILITY, 
        sessionId, 
        {
          newBookingAvailability,
        },
        this.constructPermissionsParams()
      )
  }


}
