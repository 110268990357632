import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { mcqStyleSub, StyleprofileService } from 'src/app/core/styleprofile.service';
import { IContentElementMcq, IContentElementMcqOption, McqDisplay } from 'src/app/ui-testrunner/element-render-mcq/model';
import { IContentElementText, TextParagraphStyle } from 'src/app/ui-testrunner/element-render-text/model';
import { ElementType, IElementTypeDef } from 'src/app/ui-testrunner/models';
import { ElementTypeDefs } from "src/app/ui-testrunner/models/ElementTypeDefs";
import { LoginGuardService } from '../../api/login-guard.service';
import { AuthScopeSetting, AuthScopeSettingsService } from '../auth-scope-settings.service';
import { EditingDisabledService } from '../editing-disabled.service';
import { ItemComponentEditService } from '../item-component-edit.service';
import { createDefaultElement } from '../item-set-editor/models';
import { generateDefaultElementTextLink } from '../item-set-editor/models/index';
import { bindFormControls } from '../services/data-bind';
import { indexOf } from '../services/util';

export function checkInputBoxArrayFormat(input){
  const regex = /^(?:[\d]+,[ ]*)*[\d]+$/;
  if (input && input != '')
  return regex.exec(input);
  return true;
}

@Component({
  selector: 'element-config-mcq',
  templateUrl: './element-config-mcq.component.html',
  styleUrls: ['./element-config-mcq.component.scss']
})
export class ElementConfigMcqComponent implements OnInit {

  @Input() element:IContentElementMcq;

  ElementType = ElementType; 
  
  isShowAdvancedOptions = new FormControl(false);
  isMultiSelect = new FormControl(false);
  isSelectToggle = new FormControl(false);
  isScrambled = new FormControl(false);
  isLimittedWidth = new FormControl(false);
  isOptionLabelsDisabled = new FormControl(false);
  isRadioDisabled = new FormControl(false);
  isContentsCentered = new FormControl(false);
  isContentsCenteredVertically = new FormControl(false);
  gridNumColumns = new FormControl();
  scoreWeight = new FormControl();
  isShowMathMcqTransfer:boolean;
  buttonLabels = 'ABCDEFGHIJKLMNOP'.split('');
  
  isEditingEntryId:boolean;
  startEditingEntryId(){
    if(confirm('Edit Entry ID? You should avoid doing this unless the entry is blank or duplicated due to an import.')){
      this.isEditingEntryId = true;
    }
  }

  subElementTypes:IElementTypeDef[] = [
    {
      ... ElementTypeDefs.TEXT,
      feedProps: {
        paragraphStyle: null,
      }
    },
    {
      ... ElementTypeDefs.TEXT,
      caption: 'Advanced Text',
      feedProps: {
        paragraphStyle: TextParagraphStyle.ADVANCED_INLINE,
        advancedList: [
          createDefaultElement(ElementType.MATH),
          createDefaultElement(ElementType.TEXT)
        ]
      }
    },
    ElementTypeDefs.MATH,
    ElementTypeDefs.IMAGE,
    ElementTypeDefs.TABLE,
    ElementTypeDefs.FRAME,
    ElementTypeDefs.AUDIO
  ]

  constructor(
    private itemComponentEdit: ItemComponentEditService,
    private authScopeSettings: AuthScopeSettingsService,
    private editingDisabled: EditingDisabledService,
    private loginGuard: LoginGuardService,
    private profile: StyleprofileService
  ) { }
  
  defaultStyles;
  customStr = 'custom';
  ngOnInit() {
    this.isShowMathMcqTransfer = window['isShowMathMcqTransfer'];

    const formControls = [
      {f: this.isShowAdvancedOptions, p:'isShowAdvancedOptions' },
      {f: this.isMultiSelect, p:'isMultiSelect' },
      {f: this.isSelectToggle, p:'isSelectToggle' },
      {f: this.isScrambled, p:'isScrambled' },
      {f: this.scoreWeight, p:'scoreWeight' },
      {f: this.isLimittedWidth, p:'isLimittedWidth' },
      {f: this.isOptionLabelsDisabled, p:'isOptionLabelsDisabled' },
      {f: this.isRadioDisabled, p:'isRadioDisabled'},
      {f: this.isContentsCentered, p:'isContentsCentered' },
      {f: this.isContentsCenteredVertically, p:'isContentsCenteredVertically' },
      {f: this.gridNumColumns, p:'gridNumColumns' },
    ];
    this.element.options.forEach((option)=>{
      if (!option.link) option.link = generateDefaultElementTextLink();
      if (!option.x) option.x = 0;
      if (!option.y) option.y = 0;
      if(!option.optionType) option.optionType = ElementType.MCQ_OPTION;
    })
    if (!this.element.isCanLink) this.element.isCanLink = false;
    bindFormControls(this.element, formControls);
    this.element.minHeight = 3;

    this.defaultStyles = this.profile.getDefaultMcqStyles();
    if (!this.element.currentSettings) this.element.currentSettings = this.customStr;
    if (!this.element.mcqAreaHeight) this.element.mcqAreaHeight = 10;

    if(this.element.isDynamicallyCorrect){
      this.element.options.map(option => option.isCorrect = false)
    }
  }

  selectDisplayStyleVertical(){   this.element.displayStyle = McqDisplay.VERTICAL; this.setToDefault()}
  selectDisplayStyleGrid(){       this.element.displayStyle = McqDisplay.GRID; this.setToDefault()}
  selectDisplayStyleHorizontal(){ this.element.displayStyle = McqDisplay.HORIZONTAL; this.setToDefault()}
  selectDisplayStyleLikert(){
    this.loginGuard.confirmationReqActivate({
      caption: 'Are you sure you want to convert this multiple choice block to a likert interaction? This will remove any options that have already been created in this block.',
      confirm: () => {
        this.element.displayStyle = McqDisplay.LIKERT; 
        this.element.options = [];
        this.insertTextListEntry('strongly disagree');
        this.insertTextListEntry('disagree');
        this.insertTextListEntry('neural');
        this.insertTextListEntry('agree');
        this.insertTextListEntry('strongly agree');
      }
    })
  }
  selectDisplayStyleWraparound(){ this.element.displayStyle = McqDisplay.WRAP; }
  selectDisplayStyleDropdown(){   this.element.displayStyle = McqDisplay.DROPDOWN; }
  selectDisplayStyleCustomDropdown(){   this.element.displayStyle = McqDisplay.CUSTOM_DROPDOWN; }
  selectDisplayStyleBubble(){   this.element.displayStyle = McqDisplay.BUBBLE; }
  selectDisplayStyleFreeform(){   this.element.displayStyle = McqDisplay.FREEFORM; }

  isDisplayStyleFreeform(){   return this.element.displayStyle === McqDisplay.FREEFORM; }
  isDisplayStyleLikert(){     return this.element.displayStyle === McqDisplay.LIKERT; }
  isDisplayStyleVertical(){   return this.element.displayStyle === McqDisplay.VERTICAL; }
  isDisplayStyleGrid(){       return this.element.displayStyle === McqDisplay.GRID; }
  isDisplayStyleHorizontal(){ return this.element.displayStyle === McqDisplay.HORIZONTAL; }
  isDisplayStyleWraparound(){ return this.element.displayStyle === McqDisplay.WRAP; }
  isDisplayStyleDropdown(){   return this.element.displayStyle === McqDisplay.DROPDOWN; }
  isDisplayStyleCustomDropdown(){   return this.element.displayStyle === McqDisplay.CUSTOM_DROPDOWN; }
  isDisplayStyleBubble(){     return this.element.displayStyle === McqDisplay.BUBBLE; }
  
  isDisabled(attr: string) {
    let style;
    if (this.element.currentSettings && this.defaultStyles && this.defaultStyles[this.element.currentSettings]) {
      let style = this.defaultStyles[this.element.currentSettings];
    }
    let display = '';
    if (this.element.displayStyle==McqDisplay.VERTICAL) display = 'vertical';
    else if (this.element.displayStyle==McqDisplay.HORIZONTAL) display = 'horizontal';
    else if (this.element.displayStyle==McqDisplay.GRID) display = 'grid';
    return this.element.currentSettings != this.customStr && style && style[display] && style[display][attr] != undefined ? '' : null;
  }

  getFirstOption(){
    return <any> this.element.options[0];
  }
  getLastOption(){
    const options = this.element.options;
    return <any> options[options.length - 1];
  }

  setToDefault() {
    if (this.element.currentSettings == this.customStr || !this.defaultStyles || this.defaultStyles.length<=this.element.currentSettings) return;
    let style = this.defaultStyles[this.element.currentSettings];
    if (!style) return;
    switch(this.element.displayStyle) {
      case McqDisplay.VERTICAL:
        this.setSettings(style.vertical);
        break;
      case McqDisplay.GRID:
        this.setSettings(style.grid);
        break;
      case McqDisplay.HORIZONTAL:
        this.setSettings(style.horizontal);
        break;
    }
  }

  onStyleChange($event) {
    this.setToDefault();
  }

  setSettings(obj:mcqStyleSub) {
    this.isContentsCentered.setValue(obj.isContentsCentered)
    this.isContentsCenteredVertically.setValue(obj.isContentsCenteredVertically)
    this.isRadioDisabled.setValue(obj.isRadioDisabled)
    this.isSelectToggle.setValue(obj.isOptionLabelsDisabled)
    this.isOptionLabelsDisabled.setValue(obj.isSelectToggle);
  }

  insertTextListEntry(caption:string){
    const optionElement:Partial<IContentElementText> = <Partial<IContentElementText>>this.insertListEntry({id:ElementType.TEXT});
    optionElement.caption = caption;
  }

  insertListEntry(config:Partial<IElementTypeDef>){
    const elementType = config.id;
    // console.log('this.element.options', this.element.options);
    let content:any = '';
    if (elementType === ElementType.IMAGE){
      content = {url: '', scale: 1}
    }
    const optionElement = {
      ... createDefaultElement(elementType),
      elementType,
      optionType: ElementType.MCQ_OPTION,
      content,
      isCorrect: false,
      optionId: -1,
      x: 0,
      y: 0,
      link: generateDefaultElementTextLink()
    };
    
    if (config.feedProps){
      const feedProps = JSON.parse(JSON.stringify(config.feedProps));
      Object.keys(feedProps).forEach(prop => {
        optionElement[prop] = feedProps[prop];
      });
    }
    this.element.options.push(optionElement)
    this.updateChangeCounter();
    return optionElement;
  }

  isAdvTextEl(el:IContentElementText){
    if (el.paragraphStyle){
      return true;
    }
    return false;
  }

  removeElement(content:any[], element:any){
    if (window.confirm('Remove this option?')){
      let i = indexOf(content, element);
      if (i !== -1){
        content.splice(i, 1)
      }
    }
    this.updateChangeCounter();
  }

  // transformMathToText(){
  //   this.element.options.forEach(option => {
  //     if (option.elementType === 'math'){
  //       option.elementType = 'text';
  //     }
  //   })
  // }

  toggleOptionCorrect(optionElement:IContentElementMcqOption){
    optionElement.isCorrect = ! optionElement.isCorrect;
    this.itemComponentEdit.update.next(true);
  }



  updateChangeCounter = () => {
    if (!this.element._changeCounter){
      this.element._changeCounter = 0;
    }
    this.element._changeCounter ++;
  }

  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr)
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

  isScoreWeightEnabled = () => this.authScopeSettings.getSetting(AuthScopeSetting.ENABLE_SCORE_WEIGHT);

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

  canLockInputBoxesOnSelection(){
    return !this.element.isMultiSelect || this.element.maxOptions == 1;
  }

  checkInputBoxArrayFormat = ()=>checkInputBoxArrayFormat(this.element.inputBoxIDs);
}
